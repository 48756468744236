<div class="sellentt-container">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-regular fa-address-card secondary"></i> Criar Conta</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Preencha os dados abaixo para dar sequência em seu cadastro dentro da plataforma.
        </p>
      </h2>
    </div>

    <div class="sellentt-col-md-12">
      <div class="product-wrapper card h-100 pb-4 pr-4 pl-4">

        <h2 class="m-t-lg">
          <i class="fa-solid fa-building iconSellentt"></i> Dados Gerais
        </h2>
        <hr>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="phone_number_1" [ngClass]="mandatory.phone_number_1 === 1 ? 'req' : ''">Telefone</label>
              <input type="text" class="form-control phone-number" name="phone_number_1" id="phone_number_1"
                [(ngModel)]="user.phone_number_1" *ngIf="available.phone_number_1">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="mobile_number_1" [ngClass]="mandatory.mobile_number_1 === 1 ? 'req' : ''">Celular</label>
              <input type="text" class="form-control phone-number" name="mobile_number_1" id="mobile_number_1"
                [(ngModel)]="user.mobile_number_1" *ngIf="available.mobile_number_1">
            </div>
          </div>
          <div class="col-md-4">
            <label for="doc_type" class="control-label req"> Tipo de Cliente</label>
            <select name="doc_type" id="doc_type" class="form-control" [(ngModel)]="docType" (change)="changeDocType()">
              <option value="">- Selecione -</option>
              <option *ngIf="this.settings['doc_type'] == 'cpf' || this.settings['doc_type'] == 'both'" value="cpf">Pessoa Física</option>
              <option *ngIf="this.settings['doc_type'] == 'cnpj' || this.settings['doc_type'] == 'both'" value="cnpj">Pessoa Jurídica</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="docType == 'cpf'">
          <div class="col-md-4" *ngIf="available.name_pf">
            <div class="form-group">
              <label for="pf_name" [ngClass]="mandatory.name_pf === 1 ? 'req' : ''">Nome Completo</label>
              <input type="text" class="form-control" name="pf_name" id="pf_name" [(ngModel)]="user.pf_name">
            </div>
          </div>
          <div class="col-md-4" *ngIf="available.doc_number_pf">
            <div class="form-group">
              <label for="pf_doc_number" [ngClass]="mandatory.doc_number_pf === 1 ? 'req' : ''">CPF</label>
              <input type="text" class="form-control" name="pf_doc_number" id="pf_doc_number"
                [(ngModel)]="user.pf_doc_number">
            </div>
          </div>
          <div class="col-md-4" *ngIf="available.reg_number_pf">
            <div class="form-group">
              <label for="pf_reg_number" [ngClass]="mandatory.reg_number_pf === 1 ? 'req' : ''">RG</label>
              <input type="text" class="form-control" name="pf_reg_number" id="pf_reg_number"
                [(ngModel)]="user.pf_reg_number">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="docType == 'cnpj'">
          <div class="col-md-3" *ngIf="available.legal_name">
            <div class="form-group">
              <label for="pj_company_name" [ngClass]="mandatory.legal_name === 1 ? 'req' : ''">Razão
                Social</label>
              <input type="text" class="form-control" name="pj_company_name" id="pj_company_name"
                [(ngModel)]="user.pj_company_name">
            </div>
          </div>
          <div class="col-md-3" *ngIf="available.name">
            <div class="form-group">
              <label for="pj_name" [ngClass]="mandatory.name === 1 ? 'req' : ''">Nome Fantasia</label>
              <input type="text" class="form-control" name="pj_name" id="pj_name" [(ngModel)]="user.pj_name">
            </div>
          </div>
          <div class="col-md-3" *ngIf="available.doc_number">
            <div class="form-group">
              <label for="pj_doc_number" [ngClass]="mandatory.doc_number === 1 ? 'req' : ''">CNPJ</label>
              <input type="text" class="form-control" name="pj_doc_number" id="pj_doc_number"
                [(ngModel)]="user.pj_doc_number">
            </div>
          </div>
          <div class="col-md-3" *ngIf="available.reg_number">
            <div class="form-group">
              <label for="pj_reg_number" [ngClass]="mandatory.reg_number === 1 ? 'req' : ''">Inscrição Estadual</label>
              <input type="text" class="form-control" name="pj_reg_number" id="pj_reg_number"
                [(ngModel)]="user.pj_reg_number">
            </div>
          </div>
        </div>

        <h2 class="m-t-lg">
          <i class="fa-solid fa-user iconSellentt"></i> Dados de Usuário
        </h2>
        <hr>

        <div class="row">
          <div class="col-md-4" *ngIf="available.user_name">
            <div class="form-group">
              <label for="user_name" [ngClass]="mandatory.user_name === 1 ? 'req' : ''">Nome de Usuário</label>
              <input type="text" class="form-control" name="user_name" id="user_name" [(ngModel)]="user.user_name">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="email_1" class="req">E-mail</label>
              <input type="text" class="form-control" name="email_1" id="email_1" [(ngModel)]="user.email_1">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="user_password" class="req">Senha</label>
              <input type="password" class="form-control" name="user_password" id="user_password"
                [(ngModel)]="user.user_password">
            </div>
          </div>
        </div>

        <div *ngIf="available.address === 1">
          <h2 class="m-t-lg">
            <i class="fa-solid fa-address-card iconSellentt"></i> Endereço de Cobrança
          </h2>
          <hr>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_street" [ngClass]="mandatory.address === 1 ? 'req' : ''">Logradouro</label>
                <input type="text" class="form-control" name="address_street" id="address_street"
                  [(ngModel)]="user.address_street">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_number" [ngClass]="mandatory.address === 1 ? 'req' : ''">Número</label>
                <input type="text" class="form-control" name="address_number" id="address_number"
                  [(ngModel)]="user.address_number">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_more">Complemento</label>
                <input type="text" class="form-control" name="address_more" id="address_more"
                  [(ngModel)]="user.address_more">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_zipcode" [ngClass]="mandatory.address === 1 ? 'req' : ''">CEP</label>
                <input type="text" class="form-control" name="address_zipcode" id="address_zipcode"
                  [(ngModel)]="user.address_zipcode">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_district" [ngClass]="mandatory.address === 1 ? 'req' : ''">Bairro</label>
                <input type="text" class="form-control" name="address_district" id="address_district"
                  [(ngModel)]="user.address_district">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_city" [ngClass]="mandatory.address === 1 ? 'req' : ''">Cidade</label>
                <input type="text" class="form-control" name="address_city" id="address_city"
                  [(ngModel)]="user.address_city">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_state" [ngClass]="mandatory.address === 1 ? 'req' : ''">Estado</label>
                <select name="address_state" id="address_state" class="form-control" [(ngModel)]="user.address_state">
                  <option value="">- Selecione -</option>
                  <option *ngFor="let s of states" value="{{ s.name }}">{{ s.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="available.address_delivery === 1">
          <h2 class="m-t-lg">
            <i class="fa-solid fa-address-card iconSellentt"></i> Endereço de Entrega
          </h2>
          <hr>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_street_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">Logradouro</label>
                <input type="text" class="form-control" name="address_street_delivery" id="address_street_delivery"
                  [(ngModel)]="user.address_street_delivery">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_number_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">Número</label>
                <input type="text" class="form-control" name="address_number_delivery" id="address_number_delivery"
                  [(ngModel)]="user.address_number_delivery">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="address_more_delivery">Complemento</label>
                <input type="text" class="form-control" name="address_more_delivery" id="address_more_delivery"
                  [(ngModel)]="user.address_more_delivery">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_zipcode_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">CEP</label>
                <input type="text" class="form-control" name="address_zipcode_delivery" id="address_zipcode_delivery"
                  [(ngModel)]="user.address_zipcode_delivery">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_district_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">Bairro</label>
                <input type="text" class="form-control" name="address_district_delivery" id="address_district_delivery"
                  [(ngModel)]="user.address_district_delivery">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_city_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">Cidade</label>
                <input type="text" class="form-control" name="address_city_delivery" id="address_city_delivery"
                  [(ngModel)]="user.address_city_delivery">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="address_state_delivery"
                  [ngClass]="mandatory.address_delivery === 1 ? 'req' : ''">Estado</label>
                <select name="address_state_delivery" id="address_state_delivery" class="form-control"
                  [(ngModel)]="user.address_state_delivery">
                  <option value="">- Selecione -</option>
                  <option *ngFor="let s of states" value="{{ s.name }}">{{ s.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="available.contact === 1">
          <h2 class="m-t-lg">
            <i class="fa-solid fa-phone-volume iconSellentt"></i> Contatos
          </h2>
          <hr>

          <div class="row">
            <div class="col-md-3" *ngIf="available.phone_number_1">
              <div class="form-group">
                <label for="phone_number_1_replica" [ngClass]="mandatory.phone_number_1 === 1 ? 'req' : ''">Telefone
                  1</label>
                <input type="text" class="form-control" name="phone_number_1_replica" id="phone_number_1_replica"
                  disabled [(ngModel)]="user.phone_number_1">
              </div>
            </div>
            <div class="col-md-3" *ngIf="available.phone_number_2">
              <div class="form-group">
                <label for="phone_number_2" [ngClass]="mandatory.phone_number_2 === 1 ? 'req' : ''">Telefone 2</label>
                <input type="text" class="form-control phone-number" name="phone_number_2" id="phone_number_2"
                  [(ngModel)]="user.phone_number_2">
              </div>
            </div>
            <div class="col-md-3" *ngIf="available.mobile_number_1">
              <div class="form-group">
                <label for="mobile_number_1_replica" [ngClass]="mandatory.mobile_number_1 === 1 ? 'req' : ''">Celular
                  1</label>
                <input type="text" class="form-control" name="mobile_number_1_replica" id="mobile_number_1_replica"
                  disabled [(ngModel)]="user.mobile_number_1">
              </div>
            </div>
            <div class="col-md-3" *ngIf="available.mobile_number_2">
              <div class="form-group">
                <label for="mobile_number_2" [ngClass]="mandatory.mobile_number_2 === 1 ? 'req' : ''">Celular 2</label>
                <input type="text" class="form-control phone-number" name="mobile_number_2" id="mobile_number_2"
                  [(ngModel)]="user.mobile_number_2">
              </div>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="customFields.length > 0">
      <h2 class="m-t-lg">
        <i class="fa-solid fa-circle-info iconSellentt"></i> Informações Adicionais
      </h2>
      <hr>

      <div class="row">
        <div class="col-md-6" *ngFor="let customFields of field">

        </div>
      </div>
    </div> -->

        <div class="row mt-4">
          <div class="col-md-12">
            <input type="checkbox" id="use_terms_accepted">&nbsp;
            <label for="use_terms_accepted">
              <b>
                LI E ACEITO OS
              <a href="https://sellentt.com.br/legal/politica-de-privacidade" target="_blank"> TERMOS DE USO</a>
            </b>
            </label>
          </div>
        </div>

        <div class="row mt-4">
          <!-- <div class="col-md-6 text-left">
            <p class="form__switch">
              Já possui uma conta? <b><a [routerLink]="['/login']">Clique aqui</a></b>
            </p>
          </div> -->
          <div class="col-md-12 mt-4 text-right">
            <button type="button" class="btn btn-warning" (click)="save()" id="save">
              <i class="fa-solid fa-pen-to-square"></i> Finalizar Cadastro
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<div *ngIf="type === 'card'" class="product-wrapper sellentt-card h-100">
  <!-- product header -->
  <div class="product-header">
    <div class="product-top d-flex justify-content-between align-items-center">
      <div *ngIf="isStockActive" class="product-tag">
        <div class="d-flex align-items-center">
          <span *ngIf="product.total_discount_percentage > 0" class="percentage">{{ product.total_discount_percentage ||
            0
            }}%</span>
          <span *ngIf="showStock; else dontShowQuantityStock" style="font-size: 10px;">
            restam <br> {{hasStock(product) ? product.stock : 0}} <br> itens
          </span>
          <ng-template #dontShowQuantityStock>
            <span *ngIf="hasStock(product); else noStock" style="font-size: 10px;">
              Em estoque
            </span>
          </ng-template>
          <ng-template #noStock>
            <span style="font-size: 10px;">
              Fora de estoque
            </span>
          </ng-template>
        </div>
      </div>
      <!-- <div class="product-rating text-end">
        <div class="stars">
          <i class="fa-solid fa-star icon"></i>
          <i class="fa-solid fa-star icon"></i>
          <i class="fa-solid fa-star icon"></i>
          <i class="fa-solid fa-star icon"></i>
          <i class="fa-solid fa-star icon"></i>
          &nbsp;(0)
        </div>
      </div> -->
    </div>



    <div class="product-wishlist" *ngIf="isLoggedIn">
      <div class="form-check">
        <input class="form-check-input" (change)="openWishlist(product)" type="checkbox" name="wishlist">
        <label class="form-check-label" (click)="openWishlist(product)"></label>
      </div>
    </div>

  </div>


  <!-- product content -->
  <div class="product-img" (click)="openProductDetails(product)">
    <img src="{{ product.image }}" class="card-img-top" width="160px" height="160px"
      alt="Imagem do produto {{ product.code }}">
  </div>
  <div class="product-content card-body p-0">
    <h5 class="product-description">
      
      <!-- <div class="row mb-2">
        <div class="col-lg-12">
          <button class="rounded btn btn-primary form-control" id="measure_units" (click)="openDetailsProducts(product)">
            <i class="fa-solid fa-magnifying-glass"></i> 
        </button>
        </div>
      </div> -->
      
      <a (click)="openProductDetails(product)" class="product-search" title="{{ product.name }} - {{ product.code }}">{{
        product.name }} - {{ product.code }}
      </a>
    </h5>
    <del *ngIf="product.default_discount > 0" class="del" style="color: gray;">
      {{ product.price_no_discounts | currency : 'BRL' }}
    </del>
    <ng-template #del>
      <del class="del">&nbsp;</del>
    </ng-template>
    <h3 style="font-size: 23px;">
      {{ product.price_with_discounts | currency : 'BRL' }}
      <button *ngIf="!removeTextIpiStPauta" class="btn btn-primary btn-sm float-right" (click)="openInfos(product)"
        title="Informações Adicionais">
        <i class="fa fa-info-circle"></i></button>
    </h3>
    <span *ngIf="hasStock(product); else noStock" style="color: green; font-weight: bolder;">
      Em estoque!
    </span>
    <ng-template #noStock>
      <span style="color: red; font-weight: bolder;">
        Fora de estoque!
      </span>
    </ng-template>
  </div>
  <div class="input-group mb-2 mb-0 mr-4 div-qtd">
    <span class="qtd-field"><i class="fa-solid fa-caret-right iconSellentt"></i>
      &nbsp;Quantidade: </span>
    <input type="number" id="{{product.id}}_qtd" step="{{product.multiple}}" class="form-control" />
  </div>
  <!-- product bottom -->
  <button type="button" (click)="openProductDetails(product)" class="btn btn-primary w-100 font-weight-bold"
    *ngIf="hasStock(product); else noStockButton">
    <ng-container *ngIf="!isLoggedIn && this.storeAccess === 2; else buttonBuy">
      <!-- <i class="fa-solid fa-shopping-cart"></i> -->
      Faça login ou cadastre-se para ver os preços e comprar
    </ng-container>
    <ng-template #buttonBuy>
      <ng-container>
        <i class="fa-solid fa-shopping-cart"></i>
        Comprar
      </ng-container>
    </ng-template>
  </button>
  <ng-template #noStockButton>
    <button type="button" class="btn btn-danger w-100 font-weight-bold">
      <i class="fa-solid fa-circle-xmark"></i>
      Indisponível
    </button>
  </ng-template>
</div>

<!-- <tr *ngIf="type === 'list'">
  <td><img src="{{ product.image }}" alt="Imagem do produto {{ product.code }}" width="80px"></td>
  <td>
    <div class="product-tag">
      <div class="d-flex align-items-center">
        <span *ngIf="product.total_discount_percentage > 0" class="percentage">{{ product.total_discount_percentage ||
          0
          }}%</span>
        <span *ngIf="hasStock(product); else noStock" style="font-size: 10px;">
          restam <br>{{product.stock}}<br> itens
        </span>
        <ng-template #noStock>
          <span style="font-size: 10px;">
            Fora de estoque!
          </span>
        </ng-template>
      </div>
    </div>
  </td>
  <td><a (click)="openProductDetails(product)">{{ product.name }} - {{ product.code }}</a></td>
  <td><input placeholder="Quantidade" type="number" id="add_cart_{{product.id}}" class="form-control" /></td>
  <td>
    <div class="d-flex flex-column">
      <del *ngIf="product.default_discount > 0" class="del" style="color: gray;">
        {{ product.price_no_discounts | currency : 'BRL' }}
      </del>
      <ng-template #del>
        <del class="del">&nbsp;</del>
      </ng-template>
      <strong class="price_with_discounth">
        {{ product.price_with_discounts | currency : 'BRL' }}
      </strong>
    </div>
  </td>
  <td>
    <button *ngIf="hasStock(product)" type="button" class="btn btn-primary" (click)="addToCart(product)">
      <i class="fa-solid fa-shopping-cart text-white"></i>
    </button>
  </td>
</tr> -->

<ng-container *ngIf="type === 'list'">
  <div class="row m-2">

    <div class="col-md-2 col-4">
      <img src="{{ product.image }}" alt="Imagem do produto {{ product.code }}" width="80px">
    </div>
    <div class="col-md-1 col-4">
      <div *ngIf="isStockActive" class="product-tag">
        <div class="d-flex align-items-center">
          <span *ngIf="product.total_discount_percentage > 0" class="percentage">{{ product.total_discount_percentage ||
            0
            }}%</span>
          <span *ngIf="showStock; else dontShowQuantityStock" style="font-size: 10px;">
            restam <br>{{hasStock(product) ? product.stock : 0}}<br> itens
          </span>
          <ng-template #dontShowQuantityStock>
            <span *ngIf="hasStock(product); else noStock" style="font-size: 10px;">
              Em estoque
            </span>
          </ng-template>
          <ng-template #noStock>
            <span style="font-size: 10px;">
              Fora de estoque
            </span>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-4">
      <a (click)="openProductDetails(product)">{{ product.name }} - {{ product.code }}</a>
    </div>

    <div class="col-md-1 col-4">
      <div class="d-flex flex-column">
        <del *ngIf="product.default_discount > 0" class="del" style="color: gray;">
          {{ product.price_no_discounts | currency : 'BRL' }}
        </del>
        <ng-template #del>
          <del class="del">&nbsp;</del>
        </ng-template>
        <strong class="price_with_discounth">
          {{ product.price_with_discounts | currency : 'BRL' }}
        </strong>
      </div>
    </div>

    <div class="col-md-3 col-4">
      <input placeholder="Quantidade" type="number" id="add_cart_{{product.id}}" class="form-control" />
    </div>

    <div class="col-md-1 col-4" *ngIf="hasStock(product)">
      <button type="button" class="btn btn-primary" (click)="addToCart(product)">
        <i class="fa-solid fa-shopping-cart text-white"></i>
      </button>
    </div>

  </div>
  <hr class="m-0">
</ng-container>



<!-- clicar em wishlist abre esse modal para as opções-->
<div class="modal inmodal" id="modal-wishlist-{{product.id}}" tabindex="-1" role="dialog" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">

      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <h4 class="modal-title" style="color: var(--bg-secondary);">
          <i class="fa-solid fa-heart" style="color: var(--bg-secondary);"></i> Lista de Desejos
        </h4>


      </div>

      <div class="modal-body">

        <!-- Formulário para criar uma nova lista de desejos -->
        <div class="form-group">
          <div class="d-flex align-items-center">
            <label class="col-md-2" style="margin-top: 9px;">Nome:</label>
            <input type="text" class="form-control col-md-6" name="newWishlistName" id="newWishlistName"
              [(ngModel)]="newWishlistName" placeholder="Digite o nome da nova lista">
            <button type="button" class="btn btn-primary float-right col-md-4 ml-1" (click)="createNewWishlist()">
              <i class="fas fa-plus"></i> Criar lista
            </button>
          </div>
          <hr>
        </div>

        <!-- <div *ngIf="!showSpinner" class="sk-spinner sk-spinner-three-bounce m-5 w-100 d-flex justify-content-center align-items-center">
          <div class="loader"></div>
        </div> -->

        <!-- Lista de desejos existentes -->
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let wishlist of wishlists">
            <div class="d-flex justify-content-between align-items-center">


              <div class="form-check">
                <input class="form-check-input" (change)="onChangeFavorite(product, wishlist.id)" type="checkbox"
                  name="wishlist" id="wishlist_{{ wishlist.id }}_{{product.id}}">
                <label class="form-check-label" for="wishlist_{{ product.id }}"></label>
              </div>

              <span>{{ wishlist.name }}</span>

              <div class="d-flex">
                <button type="button" class="btn btn-success" data-dismiss="modal" aria-label="Close"
                  [routerLink]="['/favorites-details', wishlist.id]" style="margin-right: 5px;">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </div>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



<!-- modal aqui para inserir ao clicar em info de produtos-->

<div class="modal inmodal" id="modal-infos-{{product.id}}" tabindex="-1" role="dialog" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated fadeIn">
      <button type="button" class="btn btn-white" data-dismiss="modal"
        style="text-align:right;border: 1px solid #ffff;"><i class="fas fa-times"></i></button>
      <div class="modal-body" style="background:#ffff;">
        <div class="wrapper_view">
          <div class="wrapper_cards" *ngIf="!showSpinner">
            <div class="row" *ngIf="insideCatalog"> 
              <div class="col-lg-6">
                <div class="ibox">
                  <div class="content_ibox">
                    <div class="zoom">
                      <img src="{{ product.image }}" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="product-sort-info">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td style="width: 25%;">Código</td>
                        <td style="width: 75%;">{{ product.code || '-' }}</td>
                      <tr>
                        <td style="width: 25%;">EAN</td>
                        <td style="width: 75%;">{{ product.ean || '-' }}</td>
                      </tr>
                      <tr>
                        <td style="width: 25%;">IPI</td>
                        <td style="width: 75%;">{{ product.ipi || 0 }}%</td>
                      </tr>
                      <tr>
                        <td style="width: 25%;">ST</td>
                        <td style="width: 75%;">{{ product.st || 0 }}%</td>
                      </tr>
                      <tr>
                        <td style="width: 25%;">PAUTA</td>
                        <td style="width: 75%;">{{ product.pauta || 0 }}%</td>
                      </tr>
                      <tr>
                        <td style="width: 25%;">Descrição</td>
                        <td style="width: 75%;">{{ product.description || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between align-items-center mt-3 mb-5">
                    <div class="price">
                      <p>
                        <del class="del" style="color: gray;">{{ product.price_no_discounts || 0 | currency: 'BRL' }}</del>
                      </p>
                        <h2>
                          Valor: 
                          {{ product.price_with_discounts || 0 | currency : 'BRL' }}
                        </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row_info" *ngIf="!insideCatalog">

              <div class="col-lg-4">
                <div class="ibox">
                  <div class="title_ibox">
                    <label class="t_label">
                      <i class="fa fa-info-circle" style="color: var(--bg-secondary); margin-right: 5px;"></i> PAUTA
                    </label>
                  </div>
                  <div class="content_ibox">
                    <span>
                      <h4>{{product.pauta || 0}}%</h4>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="ibox ">
                  <div class="title_ibox">
                    <label class="t_label">
                      <i class="fa fa-info-circle" style="color: var(--bg-secondary); margin-right: 5px;"></i> ST
                    </label>
                  </div>
                  <div class="content_ibox">
                    <span>
                      <h4>{{product.st || 0}}%</h4>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="ibox">
                  <div class="title_ibox">
                    <label class="t_label">
                      <i class="fa fa-info-circle" style="color: var(--bg-secondary); margin-right: 5px; "></i> IPI
                    </label>
                  </div>
                  <div class="content_ibox">
                    <span>
                      <h4>{{product.ipi || 0}}%</h4>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modal-product-grade-{{product.id}}" tabindex="-1" role="dialog" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" id="modal-dialog-banner">
    <div class="modal-content animated fadeIn">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
        <h1>
          <i class="fa-solid fa-landmark"></i> Grade de produtos
        </h1>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Produto</th>
                  <!-- <td scope="col">Variações</td> -->
                  <th scope="col">Quantidade</th>
                  <th scope="col">Preço</th>
                  <th scope="col">Total</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of currentGrade.products">
                  <td><a><img class="img-product" [src]="product.image"
                        style="width: 50px; height: 50px;"></a></td>
                  <td class="h6">
                    {{product.name}} ({{product.code}})
                  </td>
                  <!-- <td>
                    <div class="tooltip">
                      <i class="fa-solid fa-circle-info"></i>
                      <span class="tooltiptext" *ngIf="product.variations.length > 0">
                        <div class="h4 text-center mb-3">
                          Variações
                        </div>
                        <div *ngFor="let variation of product.variations">
                          <ul>
                            <li>
                              <b class="h5">{{variation.name}}: </b>
                              <span class="h5">{{variation.value}}</span>
                            </li>
                          </ul>
                        </div>
                      </span>
                    </div>
                  </td> -->
                  <td>
                    <input type="number" class="form-control w-50" value="{{product.quantity || 1}}"
                      id="add_cart_{{product.id}}" (change)="changeQtd(product)" />
                  </td>
                  <td>
                    {{(product.price_with_discounts || 0) | currency : 'BRL'}}
                  </td>
                  <td>
                    {{((product.quantity || 1) * product.price_with_discounts) | currency : 'BRL'}}
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary" (click)="addToCart(product)"
                      *ngIf="!product.isInsideCart">
                      <i class="fa-solid fa-shopping-cart text-white"></i>
                    </button>
                    <button type="button" class="btn btn-danger" (click)="removeFromCart(product)"
                      *ngIf="product.isInsideCart">
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
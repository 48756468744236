<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-solid fa-store secondary"></i> Minhas Lojas</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Informações de sua loja representada dentro da plataforma.</p>
      </h2>
    </div>
    <div class="panel-group sellentt-accordion" id="accordion" *ngIf="stores.length > 0">
      <div class="panel panel-default" *ngFor="let store of stores">
        <div class="panel-heading">
          <h4 class="panel-title">
            <div *ngIf="user.store.id === store.id" class="mb-4">
              <i class="fa-solid fa-check-circle mr-1" style="color: green;"></i><span>Você está fazendo pedido para esta loja, caso queira mudar, acesse <a [routerLink]="['/user-profile']">Meus Dados</a></span>
            </div>
            <div class="sellentt-row info-container">
              <div class="sellentt-col-md-2 info-col sellentt-col-sm-10 sellentt-col-xs-10">
                <div class="info-title">Cliente</div>
                <div class="info-content">{{ store.name || '-'}}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">E-mail</div>
                <div class="info-content">{{ store.email || '-'}}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Segmento</div>
                <div class="info-content">{{ store.user_group_id || '-'}}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Documento</div>
                <div class="info-content">{{ store.doc_number || '-'}}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Criado em</div>
                <div class="info-content">{{ store.created_at | date: 'dd/MM/yyyy' }}</div>
              </div>
              <div class="sellentt-col-md-2 sellentt-col-sm-2 sellentt-col-xs-2 info-col-action">
                <div class="info-content">
                  <!-- <a class="info-action-details" data-toggle="collapse" data-parent="#accordion" href="#collapse-{{store.id}}">Detalhes do cliente</a> -->
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse-{{store.id}}">
                    <i class="fa-solid fa-pen-to-square"></i>  <span class="hidden-xs"> Detalhes da loja</span>
                  </a>
                </div>
              </div>
            </div>
          </h4>
        </div>

        <div id="collapse-{{store.id}}" class="panel-collapse collapse in">
          <hr class="initial-divisor">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="text-bold">Dados do Cliente</h2>
                  </div>
                </div>
                <hr>
                <div class="form-group">
                  <label for="name">
                    <i class="fa-solid fa-building"></i> Razão Social
                  </label>
                  <input type="text" class="form-control" name="name" id="name" [(ngModel)]="store.name" disabled>
                </div>
                <div class="form-group">
                  <label for="name">
                    <i class="fa-solid fa-building"></i> Nome Fantasia
                  </label>
                  <input type="text" class="form-control" name="company_name" id="company_name"
                    [(ngModel)]="store.company_name" disabled>
                </div>
                <div class="form-group">
                  <label for="title">
                    <i class="fa-solid fa-id-card"></i> CNPJ
                  </label>
                  <input type="text" class="form-control" name="doc_number" id="doc_number"
                    [(ngModel)]="store.doc_number" disabled>
                </div>
                <div class="form-group">
                  <label for="reg_number">
                    <i class="fa-solid fa-book"></i> Inscrição Estadual
                  </label>
                  <input type="text" class="form-control" name="reg_number" id="reg_number"
                    [(ngModel)]="store.reg_number" disabled>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="text-bold">Dados de Endereço</h2>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="address_street">
                        <i class="fa-solid fa-road"></i> Logradouro
                      </label>
                      <input type="text" class="form-control" name="address_street" id="address_street"
                        [value]="store.address_street" disabled>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="address_number">
                        <i class="fa-solid fa-list-ol"></i> Número
                      </label>
                      <input type="text" class="form-control" name="address_number" id="address_number"
                        [value]="store.address_number" disabled>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="address_more">
                        <i class="fa-solid fa-person-circle-plus"></i> Complemento
                      </label>
                      <input type="text" class="form-control" name="address_more" id="address_more"
                        [value]="store.address_more" disabled>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="address_zipcode">
                        <i class="fa-solid fa-location-dot"></i> CEP
                      </label>
                      <input type="text" class="form-control" name="address_zipcode" id="address_zipcode"
                        [value]="store.address_zipcode" disabled>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="address_district">
                        <i class="fa-solid fa-earth-europe"></i> Bairro
                      </label>
                      <input type="text" class="form-control" name="address_district" id="address_district"
                        [value]="store.address_district" disabled>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="address_city">
                        <i class="fa-solid fa-map-location"></i> Cidade
                      </label>
                      <input type="text" class="form-control" name="address_city" id="address_city"
                        [value]="store.address_city" disabled>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="address_state">
                        <i class="fa-solid fa-flag-usa"></i> Estado
                      </label>
                      <input type="text" class="form-control" name="address_state" id="address_state"
                        [value]="store.address_state" disabled>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="text-bold">Dados para Contato</h2>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email_1">
                        <i class="fa-solid fa-at"></i> E-mail
                      </label>
                      <input type="text" class="form-control" name="email_1" id="email_1" [value]="store.email_1"
                        disabled>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="phone_number_1">
                        <i class="fa-solid fa-square-phone"></i> Telefone
                      </label>
                      <input type="text" class="form-control" name="phone_number_1" id="phone_number_1"
                        [value]="store.phone_number_1" disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

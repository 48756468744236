import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  ecommerceConfigs = {};

  constructor(
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.ecommerceConfigs['instagram'] = this.localStorageService.get('instagram_link');
    this.ecommerceConfigs['facebook'] = this.localStorageService.get('facebook_link');
    this.ecommerceConfigs['linkedin'] = this.localStorageService.get('linkedin_link');
    this.ecommerceConfigs['email'] = this.localStorageService.get('store_mail_from_email');
    this.ecommerceConfigs['telefone'] = this.localStorageService.get('mobile_number_1');
  }

}

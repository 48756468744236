<div class="sellentt-container" *ngIf="loadedData">
    <div class="sellentt-row m-t-lg">
        <div class="sellentt-col-xl-12">
            <div class="sellentt-row">
                <div class="sellentt-col-xl-12">
                    <div class="product-wrapper card h-100 p-5">
                        <div class="sellentt-row">
                            <div class="sellentt-col-x1-6">
                                <div class="sellentt-row">
                                    <div class="sellett-col-md-6" align="left">
                                        <h1 class="text-bold secondary">
                                            <i class="fa-solid fa-file-invoice"></i> Dados do Pedido
                                        </h1>
                                    </div>
                                    <div class="sellett-col-md-6" align="right">
                                      <button type="button" class="btn btn-warning btn-lg mr-2" (click)="addAllToCart()" >
                                        <i class="fa-solid fa-copy"></i> Cópia de carrinho
                                      </button>
                                      <button *ngIf="showAssements" type="button" class="btn btn-warning btn-lg mr-2" (click)="reviewOrder()">
                                        <i class="fa-solid fa-star"></i> Avaliação
                                      </button>
                                      <a *ngIf="showReceived" [routerLink]="['/orders', order['code'], 'confirmation']" class="btn btn-warning btn-lg" title="Confirmar recebimento">
                                        <i class="fa-solid fa-check"></i> Confirmação de Recebimento
                                      </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="code">
                                        <i class="fa-solid fa-user-check secondary"></i> Código
                                    </label>
                                    <input type="text" class="form-control" name="code" id="code" [(ngModel)]="order.code" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="erp_code">
                                        <i class="fa-solid fa-user-check secondary"></i> Código ERP
                                    </label>
                                    <input type="text" class="form-control" name="erp_code" id="erp_code" [(ngModel)]="order.erp_id" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="status_name">
                                        <i class="fa-solid fa-clone secondary"></i> Status
                                    </label>
                                    <input type="text" class="form-control" name="status_name" id="status_name" [(ngModel)]="order.status && order.status.name" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="created_at">
                                        <i class="fa-solid fa-calendar-days secondary"></i> Data
                                    </label>
                                    <input type="text" class="form-control" name="created_at" id="created_at" [(ngModel)]="order.created_at" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-12">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-comment fa-fade secondary"></i> Observação
                                    </label>
                                    <textarea class="form-control" name="shipping_cost" id="shipping_cost"
                                        rows="8" [(ngModel)]="order.obs" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-basket-shopping secondary"></i> Produtos
                                </h1>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                          <div class="panel-group sellentt-accordion" id="accordion" *ngIf="order.products.length > 0">
                            <div class="panel panel-default" *ngFor="let item of order.products">
                              <div class="panel-heading">
                                <h4 class="panel-title">
                                  <div class="sellentt-row info-container">
                                    <div class="sellentt-col-md-1 info-col sellentt-col-sm-4 sellentt-col-xs-4">
                                      <!-- <div class="info-title"></div> -->
                                      <div class="info-content"><img src="{{ item.image }}" class="product-image"></div>
                                    </div>
                                    <div class="sellentt-col-md-2 sellentt-col-sm-7 sellentt-col-xs-7 info-col">
                                      <!-- <div class="info-title">Descrição</div> -->
                                      <div class="info-content">{{ item.name }} ({{ item.code }})</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Quantidade</div>
                                      <div class="info-content">{{ (item.quantity || 0) | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Preço Tabela</div>
                                      <div class="info-content">{{ (item.table_price || 0) | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Forma de Pag.</div>
                                      <div class="info-content">{{ (item.payment_method_discount || 0) | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Frete</div>
                                      <div class="info-content">{{ (item.shipping_discount || 0) | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Preço Unit.</div>
                                      <div class="info-content">{{ item.unit_price | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">IPI</div>
                                      <div class="info-content">{{ item.ipi_value | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">ST</div>
                                      <div class="info-content">{{ item.st_value | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Preço Final</div>
                                      <div class="info-content">{{ item.final_price / item.quantity | currency : 'BRL' }}</div>
                                    </div>
                                    <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Subtotal Final</div>
                                      <div class="info-content">{{ (item.final_price) | currency : 'BRL' }}</div>
                                    </div>
                                    <!-- <div class="sellentt-col-md-1 hidden-xs info-col">
                                      <div class="info-title">Subtotal Bruto</div>
                                      <div class="info-content">{{ (item.final_price) | currency : 'BRL' }}</div>
                                    </div> -->
                                    <div class="sellentt-col-md-1 sellentt-col-sm-1 sellentt-col-xs-1 info-col-action hidden-md hidden-lg">
                                      <div class="info-content">
                                        <a class="btn-collapse btn-{{item.id}}" data-toggle="collapse" data-parent="#accordion" href="#collapse-{{item.id}}" (click)="openPanelContent(item.id)"></a>
                                      </div>
                                    </div>
                                    <div class="sellentt-col-md-1 sellentt-col-xs-12 hidden-md hidden-lg info-col flex-content">
                                      <div class="info-content">{{ item.quantity + (item.quantity > 1 ? ' unidades' : ' unidade') }}</div>
                                    </div>
                                  </div>
                                </h4>
                              </div>

                              <div id="collapse-{{item.id}}" class="panel-collapse collapse in">
                                <hr class="initial-divisor">
                                <div class="panel-body">
                                  <div class="row product-list">
                                    <div class="product-line"><span class="product-title">Preço Tabela:</span><span class="product-content"> {{ item.price_table || 0 | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Forma de Pag.:</span><span class="product-content"> {{ (item.payment_method_discount || 0) | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Frete:</span><span class="product-content"> {{ (item.shipping_discount || 0) | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Preço Unit.:</span><span class="product-content"> {{ item.unit_price | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">IPI:</span><span class="product-content"> {{ item.ipi_value | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">ST:</span><span class="product-content"> {{ item.st_value | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Preço final:</span><span class="product-content"> {{ item.unit_price | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Subtotal Final:</span><span class="product-content"> {{ item.final_price * item.quantity | currency : 'BRL' }}</span></div>
                                    <div class="product-line"><span class="product-title">Subtotal Bruto:</span><span class="product-content"> {{ item.final_price * item.quantity | currency : 'BRL' }}</span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-store"></i> Dados do Cliente
                                </h1>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="doc_number">
                                        <i class="fa-solid fa-building secondary"></i> Tipo de Cliente
                                    </label>
                                    <select class="form-control" [(ngModel)]="order.store.doc_type" disabled>
                                        <option value="cpf">Pessoa Física</option>
                                        <option value="cnpj">Pessoa Jurídica</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="status_name secondary">
                                        <i class="fa-solid fa-user-pen secondary"></i> Nome Completo
                                    </label>
                                    <input type="text" class="form-control" name="status_name" id="status_name" [(ngModel)]="order.store && order.store.name" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-id-card-clip secondary"></i> CPF
                                    </label>
                                    <input type="text" class="form-control" name="shipping_cost" id="shipping_cost" [(ngModel)]="order.store.doc_number" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-id-card secondary"></i> RG
                                    </label>
                                    <input type="text" class="form-control" name="shipping_cost" id="shipping_cost" [(ngModel)]="order.store.reg_number" disabled>
                                </div>
                            </div>
                        </div>

                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-cash-register"></i> Dados da Forma de Pagamento
                                </h1>
                            </div>
                        </div>
                        <hr>

                        <div class="sellentt-row" *ngIf="!order.payment_method_id && !order.external">
                          <div class="sellentt-col-md-12">
                            <div class="alert alert-warning">
                              <i class="fa-solid fa-info-circle"></i>&nbsp;
                              Não foi encontrada forma de pagamento para esse pedido...
                            </div>
                          </div>
                        </div>

                        <div class="sellentt-row" *ngIf="order.payment_method_id">
                            <div class="sellentt-col-md-6">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-credit-card secondary"></i> Forma de Pagamento
                                    </label>
                                    <input type="text" class="form-control" name="shipping_cost" id="shipping_cost"
                                        [(ngModel)]="order.payment_form && order.payment_form.name" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-6">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-clock secondary"></i> Prazo de Pagamento
                                    </label>
                                    <input type="text" class="form-control" name="shipping_cost" id="shipping_cost"
                                        [(ngModel)]="order.payment_term && order.payment_term.name" disabled>
                                </div>
                            </div>
                        </div>

                        <div class="sellentt-row" *ngIf="order.external">
                          <div class="sellentt-col-md-6">
                            <div class="form-group">
                              <label for="pagarme_order_id">
                                <i class="fa-solid fa-file-invoice-dollar secondary"></i> Status Atual na PAGAR.ME
                              </label>
                              <input type="text" class="form-control" name="pagarme_status_id" id="pagarme_status_id"
                                [(ngModel)]="order.external.pagarme_status_id" disabled>
                            </div>
                          </div>
                          <div class="sellentt-col-md-6">
                            <div class="form-group">
                              <label for="shipping_cost">
                                <i class="fa-solid fa-truck-arrow-right secondary"></i> Id do Pedido na PAGAR.ME
                              </label>
                              <input type="text" class="form-control" name="pagarme_order_id" id="pagarme_order_id"
                                [(ngModel)]="order.external.pagarme_order_id" disabled>
                            </div>
                          </div>
                          <div class="sellentt-col-md-6">
                            <div class="form-group">
                              <label for="shipping_cost" >
                                <i class="fa-solid fa-truck-arrow-right secondary"></i> Id da cobrança na PAGAR.ME
                              </label>
                              <input type="text" class="form-control" name="pagarme_charge_id" id="pagarme_chrage_id"
                                [(ngModel)]="order.external.pagarme_charge_id" disabled>
                            </div>
                          </div>
                        </div>

                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-truck-fast"></i> Frete
                                </h1>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-6">
                                <div class="form-group">
                                    <label for="shipping_cost">
                                        <i class="fa-solid fa-truck-arrow-right secondary"></i> Frete
                                    </label>
                                    <input type="text" class="form-control" name="shipping_cost" id="shipping_cost" [(ngModel)]="order.shipping_cost" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-address-card"></i> Endereço de Cobrança
                                </h1>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-6">
                                <div class="form-group">
                                    <label for="address_street">
                                        <i class="fa-solid fa-road secondary"></i> Logradouro
                                    </label>
                                    <input type="text" class="form-control" name="address_street" id="address_street"
                                        [(ngModel)]="order.store.address_street" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-2">
                                <div class="form-group">
                                    <label for="address_number">
                                        <i class="fa-solid fa-list-ol secondary"></i> Número
                                    </label>
                                    <input type="text" class="form-control" name="address_number" id="address_number"
                                        [(ngModel)]="order.store.address_number" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-4">
                                <div class="form-group">
                                    <label for="address_more">
                                        <i class="fa-solid fa-person-circle-plus secondary"></i> Complemento
                                    </label>
                                <input type="text" class="form-control" name="address_more" id="address_more"
                                    [(ngModel)]="order.store.address_more" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_zipcode">
                                        <i class="fa-solid fa-location-dot secondary"></i> CEP
                                    </label>
                                    <input type="text" class="form-control" name="address_zipcode" id="address_zipcode"
                                        [(ngModel)]="order.store.address_zipcode" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_district">
                                        <i class="fa-solid fa-earth-europe secondary"></i> Bairro
                                    </label>
                                    <input type="text" class="form-control" name="address_district" id="address_district"
                                        [(ngModel)]="order.store.address_district" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_city">
                                        <i class="fa-solid fa-map-location secondary"></i> Cidade
                                    </label>
                                    <input type="text" class="form-control" name="address_city" id="address_city"
                                        [(ngModel)]="order.store.address_city" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_state">
                                        <i class="fa-solid fa-flag-usa secondary"></i> Estado
                                    </label>
                                    <input type="text" class="form-control" name="address_state" id="address_state"
                                        [(ngModel)]="order.store.address_state" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row py-4">
                            <div class="sellett-col-md-12">
                                <h1 class="text-bold secondary">
                                    <i class="fa-solid fa-address-card"></i> Endereço de Entrega
                                </h1>
                            </div>
                        </div>
                        <hr>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-6">
                                <div class="form-group">
                                    <label for="address_street_delivery">
                                        <i class="fa-solid fa-road secondary"></i> Logradouro
                                    </label>
                                    <input type="text" class="form-control" name="address_street_delivery" id="address_street_delivery"
                                        [(ngModel)]="order.store.address_street_delivery" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-2">
                                <div class="form-group">
                                    <label for="address_number_delivery">
                                        <i class="fa-solid fa-list-ol secondary"></i> Número
                                    </label>
                                    <input type="text" class="form-control" name="address_number_delivery" id="address_number_delivery"
                                        [(ngModel)]="order.store.address_number_delivery" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-4">
                                <div class="form-group">
                                    <label for="address_more_delivery">
                                        <i class="fa-solid fa-person-circle-plus secondary"></i> Complemento
                                    </label>
                                <input type="text" class="form-control" name="address_more_delivery" id="address_more_delivery"
                                    [(ngModel)]="order.store.address_more_delivery" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="sellentt-row">
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_zipcode_delivery">
                                        <i class="fa-solid fa-location-dot secondary"></i> CEP
                                    </label>
                                    <input type="text" class="form-control" name="address_zipcode_delivery" id="address_zipcode_delivery"
                                        [(ngModel)]="order.store.address_zipcode_delivery" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_district_delivery">
                                        <i class="fa-solid fa-earth-europe secondary"></i> Bairro
                                    </label>
                                    <input type="text" class="form-control" name="address_district_delivery" id="address_district_delivery"
                                        [(ngModel)]="order.store.address_district_delivery" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_city_delivery">
                                        <i class="fa-solid fa-map-location secondary"></i> Cidade
                                    </label>
                                    <input type="text" class="form-control" name="address_city_delivery" id="address_city_delivery"
                                        [(ngModel)]="order.store.address_city_delivery" disabled>
                                </div>
                            </div>
                            <div class="sellentt-col-md-3">
                                <div class="form-group">
                                    <label for="address_state_delivery">
                                        <i class="fa-solid fa-flag-usa secondary"></i> Estado
                                    </label>
                                    <input type="text" class="form-control" name="address_state_delivery" id="address_state_delivery"
                                        [(ngModel)]="order.store.address_state_delivery" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

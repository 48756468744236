<!-- ======================= banner ============================ -->
<div *ngIf="loadedData">
  <div class="sellentt-col-xl-12">
    <div id="carousel-banners" class="carousel slide mb-4" data-ride="carousel" ride="carousel" interval="3000">
      <ol class="carousel-indicators" *ngIf="banners.length > 1">
        <li data-target="#carousel-banners" *ngFor="let banner of banners; let i = index;" attr.data-slide-to="{{i}}"
          [ngClass]="i === 0 ? 'active' : ''">
        </li>
      </ol>
      <div class="carousel-inner">
        <div [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'"
          *ngFor="let banner of banners; let i = index;">
          <img
            [ngClass]="banner !== 'default.jpg' ? 'd-block w-100 banner-image image-sellentt-has-banner' : 'd-block w-100 banner-image image-sellentt'"
            src="{{ getStorageUrl('/banners/main/' + banner, banner !== 'default.jpg' ? null : '108') }}">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ======================= banner ============================ -->

<!-- ======================= home ============================ -->
<div class="home-section" *ngIf="loadedData">
  <div class="sellentt-container">
    <div class="sellentt-row">
      <div class="sellentt-col-xl-12">
        <div class="shop-products">
          <!-- filters start -->
          <div class="shop-header mb-4" *ngIf="isLoggedIn">
            <div class="row mt-3 mb-3">
              <div class="col-md-4">
                <p>
                  <b><i class="fa-solid fa-user iconSellentt"></i> Cliente:</b> <br>
                  ({{client_selected.code ? client_selected.code : client_selected.erp_id}}) - {{client_selected.name}}
                </p>
              </div>
              <div class="col-md-4" *ngIf="client_cnpj !== ''">
                <p>
                  <b><i class="fa-solid fa-file-invoice iconSellentt"></i> CNPJ:</b>
                  <br>
                  {{client_cnpj}}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b><i class="fa-solid fa-text-height iconSellentt"></i> Tipo do Pedido:</b>
                  <br>
                  {{selectedOrderTypeName}}
                  <i class="fa-solid fa-arrows-rotate" (click)="openChangeOrderTypeModal()"> </i>
                </p>
              </div>
            </div>
          </div>

          <div class="shop-header mb-4" *ngIf="isLoggedIn">
            <div class="sellentt-row g-3 align-items-center">
              <div class="col-xl-12 order-xl-1">
                <div class="header-left">
                  <div class="d-sm-flex justify-content-between align-items-center">
                    <div class="input-group mr-4" *ngIf="false">
                      <span class="sellentt-input-group-text">
                        <i class="fa-solid fa-filter icon mr-3"></i>
                        Ordenar:
                      </span>
                      <select name="order" id="order" class="form-control form-select">
                        <option value="a to z">Alfabeto</option>
                        <option value="increasing price">Maior Preço</option>
                        <option value="descending price">Menor Preço</option>
                        <option value="growing discount">Com Desconto</option>
                        <option value="descending price">Sem Desconto</option>
                      </select>
                    </div>
                    <div class="input-group mr-4" *ngIf="false">
                      <span class="sellentt-input-group-text">Exibir: </span>
                      <select name="hitsPerPage" id="hitsPerPage" class="form-control form-select"
                        (change)="changeHitsPerPage()" [(ngModel)]="hitsPerPage">
                        <option value="10">10 por página</option>
                        <option value="15">15 por página</option>
                        <option value="20">20 por página</option>
                        <option value="25">25 por página</option>
                        <option value="30">30 por página</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-11">
                <app-products-filter [ecommerceConfigs]="ecommerceConfigs" [orderTypeId]="selectedOrderType['id']"
                  [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null"
                  [page]="page" [screen]="'home'" (filter_data)="filterProducts($event)"></app-products-filter>
              </div>
              <div class="col-md-1 p-0 shop-view-btn" style="margin-top:18px">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <!-- tabela -->
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-list-tab" (click)="changeColor($event, 'list');" data-bs-toggle="pill"
                      data-bs-target="#pills-list" type="button" role="tab" aria-controls="pills-list"
                      aria-selected="false" style="background-color: transparent!important" href="#pills-list"
                      target="_self">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        style="margin-top: 5px;">
                        <path d="M8 6H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M8 12H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M8 18H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 6H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 12H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 18H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-inactive"></path>
                      </svg>
                    </a>
                  </li>
                  <!-- listagem -->
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="pills-grid-tab" (click)="changeColor($event, 'table');"
                      data-bs-toggle="pill" data-bs-target="#pills-grid" type="button" role="tab"
                      aria-controls="pills-grid" aria-selected="true" style="background-color: transparent!important"
                      href="#pills-grid" target="_self">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 7H7V14H14V7Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-active"></path>
                        <path d="M25 7H18V14H25V7Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-active"></path>
                        <path d="M25 18H18V25H25V18Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-active"></path>
                        <path d="M14 18H7V25H14V18Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="icon-active"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <!-- filters end -->

          <h2 *ngIf="emphasis">
            <i class="fa-solid fa-star colorsecundary"></i> Destaques
          </h2>

          <!-- <button class="btn btn-whatsapp">
            <span id="whatsapp-icon-span">
              <i class="fa-brands fa-whatsapp"></i>
            </span>
          </button> -->

          <!-- Featured products list -->
          <div class="tab-content" id="featured-products" *ngIf="emphasis">
            <div class="tab-pane fade show active" id="featured-grid" role="tabpanel"
              aria-labelledby="featured-grid-tab" tabindex="0">

              <div id="carouselProducts" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" [ngClass]="{'active': i === 0}"
                    *ngFor="let group of groupedProducts; let i = index;">
                    <div
                      class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2 top-product">
                      <div class="sellentt-col" *ngFor="let item of group">
                        <app-product [type]="'card'" [product]="item" [orderTypeId]="selectedOrderType['id']"
                          [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null"></app-product>
                      </div>
                    </div>
                  </div>
                </div>

                <a class="carousel-control-prev" href="#carouselProducts" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselProducts" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>



          <h2 *ngIf="emphasis">
            <i class="fa-solid fa-bolt-lightning colorsecundary"></i> Mais Produtos
          </h2>

          <!-- products list -->
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab"
              tabindex="0">

              <div
                class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
                *ngIf="paginatedData.length > 0">
                <div class="sellentt-col" *ngFor="let item of paginatedData; trackBy: trackByFn">
                  <app-product [type]="'card'" [product]="item" [orderTypeId]="selectedOrderType['id']"
                    [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null"></app-product>
                </div>
              </div>


              <div class="sellentt-row sellentt-row-cols-12" *ngIf="paginatedData.length === 0 && loadedData">

                <div class="sellentt-col">
                  <div class="alert alert-warning text-center">
                    <h2>
                      <i class="fa-solid fa-exclamation-circle"></i>
                      Nenhum produto encontrado!
                    </h2>
                  </div>
                </div>
              </div>

              <!-- pagination -->
              <div class="pagination mt-5 justify-content-center" *ngIf="paginatedData.length > 0">
                <nav>
                  <sellentt-pagination (pageChanged)="onPageChange($event)" [page]="page" [hitsPerPage]="hitsPerPage"
                    [nbHits]="nbHits" [nbPages]="nbPages" [maxSize]="maxSize">
                  </sellentt-pagination>
                </nav>
              </div>

            </div>

            <div class="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab" tabindex="0">

              <!-- <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                          <ng-container *ngFor="let item of paginatedData">
                            <app-product [type]="'list'" [product]="item" [page]="page" [orderTypeId]="selectedOrderType['id']"
                              [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null"></app-product>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> -->

              <ng-container *ngFor="let item of paginatedData">
                <app-product [type]="'list'" [product]="item" [orderTypeId]="selectedOrderType['id']"></app-product>
              </ng-container>

              <div
                class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
                *ngIf="paginatedData.length === 0">
                <div class="sellentt-col">
                  Desculpe, não encontramos nenhum produto.
                </div>
              </div>

              <!-- pagination -->
              <div class="pagination mt-5 justify-content-center">
                <nav>
                  <sellentt-pagination (pageChanged)="onPageChange($event)" [page]="page" [hitsPerPage]="hitsPerPage"
                    [nbHits]="nbHits" [nbPages]="nbPages" [maxSize]="maxSize">
                  </sellentt-pagination>
                </nav>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loadedData"
  class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>

<div class="modal inmodal" id="banner-modal" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-lg" id="modal-dialog-banner">
    <div class="modal-content animated fadeIn">
      <div class="modal-body p-0">
        <a href="{{ ecommerceConfigs['link_image_popup'] }}" target="_blank" class="anchor-hover">
          <button (click)="closeBannerModal()" type="button" class="close custom-btn-close" data-dismiss="modal">
            <span class="close-banner-btn" aria-hidden="true">X</span>
            <span class="sr-only">Close</span>
          </button>

          <!-- Banner deve ser do tamanho 963px x 728px -->
          <img src="{{ bannerImage }}" alt="Banner" class="banner">
        </a>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal animated fade" id="modalChangeOrderType" role="dialog" style="display: none;"
  aria-modal="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">
      <div class="modal-header">
        <h4 class="modal-title"><i class="fa-solid fa-arrows-rotate iconSellentt"></i> Trocar tipo de pedido </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <label for="order_type_select">Tipo de Pedido:</label>
            <select name="order_type_select" class="form-control" id="order_type_select">
              <option value="">- Selecione -</option>
              <option *ngFor="let e of orderTypes" value="{{e.id}} {{e.code}} {{e.name}}">{{e.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancelOrderTypeChange" class="btn btn-white" data-dismiss="modal">
          <i class="fas fa-times"></i> Fechar</button>
        <button type="button" id="changeOrderType" class="btn btn-primary" (click)="changeOrderTypeEcommerce()">
          <i class="fa-solid fa-check"></i> Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
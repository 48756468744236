<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-solid fa-warehouse secondary"></i> Catálogos</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Informações de seus catálogos disponíveis para apresentação.</p>
      </h2>
    </div>
    <div class="panel-group sellentt-accordion" id="accordion" *ngIf="catalogs.length > 0">
      <div class="panel panel-default" *ngFor="let catalog of catalogs">
        <div class="panel-heading">
          <h4 class="panel-title">
            <div class="sellentt-row info-container">
              <div class="sellentt-col-md-3 info-col sellentt-col-sm-10 sellentt-col-xs-10">
                <div class="info-title">Catálogo</div>
                <div class="info-content">
                  <a (click)="copyCatalogLink(catalog)">
                    {{ catalog.catalog && catalog.catalog.name || '-' }}
                    <i class="fa-solid fa-copy"></i>
                  </a>
                </div>
              </div>
              <div class="sellentt-col-md-3 hidden-xs info-col">
                <div class="info-title">Descrição</div>
                <div class="info-content">{{ catalog.catalog && catalog.catalog.description || '-' }}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Status</div>
                <div class="info-content">{{ catalog.status === 'published' ? 'Publicado' : 'Rascunho' }}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Visualizações</div>
                <div class="info-content">{{ catalog.views }}</div>
              </div>
              <!-- <div class="sellentt-col-md-2 hidden-xs info-col">
                  <div class="info-title">Criado em</div>
                  <div class="info-content">{{ store.created_at | date: 'dd/MM/yyyy' }}</div>
                </div> -->
              <div class="sellentt-col-md-2 sellentt-col-sm-2 sellentt-col-xs-2 info-col-action">
                <div class="info-content">
                  <!-- <a class="info-action-details" data-toggle="collapse" data-parent="#accordion" href="#collapse-{{store.id}}">Detalhes do cliente</a> -->
                  <a data-toggle="collapse" data-parent="#accordion" (click)="redirect(catalog)">
                    <i class="fas fa-share"></i> <span class="hidden-xs"> Ir para catálogo</span>
                  </a>
                </div>
              </div>
            </div>
          </h4>
        </div>

        <!-- <div id="collapse-{{catalog.id}}" class="panel-collapse collapse in">
          <hr class="initial-divisor">
          <div class="panel-body">
            <form #form{{catalog.id}}="ngForm" (ngSubmit)="save(catalog)">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="text-bold">
                        <i class="fa-solid fa-clipboard secondary"></i> Dados Gerais
                      </h2>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-shop secondary"></i> Nome
                        </label>
                        <input type="text" class="form-control" name="name"
                          [(ngModel)]="catalog.catalog && catalog.catalog.name" disabled>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-heading secondary"></i> Título
                        </label>
                        <input type="text" class="form-control" name="title" [(ngModel)]="catalog.title">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-gear secondary"></i> Status
                        </label>
                        <select class="form-control" name="status" [(ngModel)]="catalog.status">
                          <option value="">- Escolha uma opção -</option>
                          <option value="draft">Rascunho</option>
                          <option value="canceled">Cancelado</option>
                          <option value="published">Publicado</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-shop secondary"></i> Link
                        </label>
                        <input type="text" class="form-control" name="catalog-link"
                          [(ngModel)]="catalog.link" disabled>
                      </div>
                    </div>
                    <div class="hidden-md hidden-lg hidden-sm col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-message"></i> Descrição
                        </label>
                        <input type="text" class="form-control" name="description"
                          [(ngModel)]="catalog.catalog && catalog.catalog.description" disabled>
                      </div>
                    </div>
                    <div class="hidden-md hidden-lg hidden-sm  col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-eye"></i> Visualizações
                        </label>
                        <input type="text" class="form-control" name="views"
                          [(ngModel)]="catalog.views" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="text-bold">
                        <i class="fa-solid fa-database secondary"></i> Dados da Loja
                      </h2>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-road secondary"></i> Logradouro
                        </label>
                        <input type="text" class="form-control" name="address_street"
                          [(ngModel)]="catalog.address_street">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-list-ol secondary"></i> Número
                        </label>
                        <input type="text" class="form-control" name="address_number"
                          [(ngModel)]="catalog.address_number">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-person-circle-plus secondary"></i> Complemento
                        </label>
                        <input type="text" class="form-control" name="address_more"
                          [(ngModel)]="catalog.address_more">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-location-dot secondary"></i> CEP
                        </label>
                        <input type="text" class="form-control" name="address_zipcode"
                          [(ngModel)]="catalog.address_zipcode">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-earth-europe secondary"></i> Bairro
                        </label>
                        <input type="text" class="form-control" name="address_district"
                          [(ngModel)]="catalog.address_district">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-map-location secondary"></i> Cidade
                        </label>
                        <input type="text" class="form-control" name="address_city"
                          [(ngModel)]="catalog.address_city">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>
                          <i class="fa-solid fa-flag-usa secondary"></i> Estado
                        </label>
                        <input type="text" class="form-control" name="address_state"
                          [(ngModel)]="catalog.address_state">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="text-bold">
                        <i class="fa-solid fa-hashtag secondary"></i> Redes Sociais
                      </h2>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-brands fa-facebook secondary"></i> Link do Facebook
                        </label>
                        <input type="text" class="form-control" name="link_facebook"
                          [(ngModel)]="catalog.link_facebook">
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-brands fa-instagram secondary"></i> Link do Instagram
                        </label>
                        <input type="text" class="form-control" name="link_instagram"
                          [(ngModel)]="catalog.link_facebook">
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          <i class="fa-brands fa-youtube secondary"></i> Link do Youtube
                        </label>
                        <input type="text" class="form-control" name="link_youtube"
                          [(ngModel)]="catalog.link_youtube">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-t-lg">
                <div class="col-md-12">
                  <button type="submit" name="edit" title="Alterar Catálogo"
                    class="btn btn-primary float-right" (click)="save(catalog)">
                      <i class="fas fa-save"></i> Alterar Catálogo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

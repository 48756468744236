<div *ngIf="!showSpinner" class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>


<div *ngIf="showSpinner">
  <div *ngIf="!hasProducts; else productList">
    <div class="sellentt-row">
      <div class="sellentt-col-md-12">
        <h1 class="text-center text-uppercase pb-5">
          <p>Não existe Produtos nesta lista</p>
        </h1>
      </div>
    </div>
  </div>
</div>



<ng-template #productList>
  <div class="sellentt-container">
    <div class="sellentt-row">
      <div class="sellentt-col-md-12" *ngFor="let wishlist of wishlists">
        <h1 class="text-center text-uppercase pb-5">
          <p>Produtos da Lista {{ wishlist.name }}</p>
        </h1>
      </div>
      <div class="panel-group sellentt-accordion" id="accordion" *ngIf="currentGrade.products.length > 0; else noProductsTemplate">
        <div class="panel panel-default product-card" *ngFor="let product of currentGrade.products">
          <div class="panel-heading">
            <h4 class="panel-title">
              <div class="sellentt-row info-container" style="text-align: center; align-items: center;">

                <div class="col-md-1 col-4">
                  <button type="button" class="btn btn-danger ml-2" (click)="delProductList(product)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>

                <div class="col-md-2 col-4">
                  <img [src]="product.image" class="card-img-top" style="width: 60px;" alt="Imagem do produto {{ product.code }}">
                </div>

                <div class="col-md-4 col-4">
                  <p style="color: black;">{{ product.name }} - {{ product.code }}</p>
                </div>

                <div class="col-md-2 col-4">
                  <div class="d-flex flex-column">
                    <del *ngIf="product.default_discount > 0" class="del" style="color: gray; background-color: #fff;">
                      {{ product.price_no_discounts | currency : 'BRL' }}
                    </del>
                    <ng-template #del>
                      <del class="del">&nbsp;</del>
                    </ng-template>
                    <strong class="price_with_discounth" style="color: var(--bg-secondary);">
                      {{ product.price_with_discounts | currency : 'BRL' }}
                    </strong>
                  </div>
                </div>

                <div class="col-md-2 col-4">
                  <input placeholder="Quantidade" type="number" id="add_cart_{{product.id}}" class="form-control" />
                </div>

                <div class="col-md-1 col-4">
                  <button type="button" class="btn btn-primary" (click)="addToCart(product)">
                    <i class="fa-solid fa-shopping-cart text-white"></i>
                  </button>
                </div>

              </div>
            </h4>
          </div>
        </div>
        <button type="button" class="btn btn-primary button-right" (click)="addAllToCart()">
          Adicionar Todos ao Carrinho
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="password-box">
  <div class="password-container">
    <form #formPasswordReset="ngForm" (ngSubmit)="resetPassword(formPasswordReset)">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center m-b-lg">Redefinição de senha</h2>
        </div>
        <div class="col-md-12" [class.input-error]="!emailInput.valid && emailInput.touched">
          <div class="form-group">
            <label *ngIf="!emailInput.valid && emailInput.touched">E-mail é obrigatório</label>
            <input type="email" class="form-control" name="email" placeholder="E-mail de cadastro" required disabled #emailInput="ngModel" email="true" [(ngModel)]="email">
          </div>
        </div>
        <div class="col-md-12" [class.input-error]="!passwordInput.valid && passwordInput.touched">
          <div class="form-group">
            <label *ngIf="!passwordInput.valid && passwordInput.touched">Campo obrigatório</label>
            <input type="password" class="form-control" name="password" placeholder="Nova senha" required #passwordInput="ngModel" [(ngModel)]="password">
          </div>
        </div>
        <div class="col-md-12" [class.input-error]="(!confirmPasswordInput.valid && confirmPasswordInput.touched) || confirmPasswordInput.value !== passwordInput.value">
          <div class="form-group">
            <label *ngIf="!confirmPasswordInput.valid && confirmPasswordInput.touched">Campo obrigatório</label>
            <input type="password" class="form-control" name="confirm-password" placeholder="Confirme a senha" required #confirmPasswordInput="ngModel" [(ngModel)]="confirmPassword">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="submit" [disabled]="!formPasswordReset.valid"><i class="fa-solid fa-save"></i> Redefinir senha</button>
        </div>
      </div>
    </form>
    <a [routerLink]="'/login'" class="back-to-login"><i class="fa-solid fa-arrow-left-long"></i> Voltar para login</a>
  </div>
</div>

<section class="ftco-section" *ngIf="loadedData">
  <div class="container">
    <span class="form__title"></span>
    <form class="form">
      <div class="text-center">
        <div id="setImage"></div>
        <br>
        <p class="social-media d-flex justify-content-center">
          <a href="{{ facebook_link }}" title="Facebook {{storeName}}" target="_blank"
            class="social-icon d-flex align-items-center justify-content-center">
            <span class="fa fa-facebook"></span>
          </a>
          <a href="{{ instagram_link }}" target="_blank" title="Instagram {{storeName}}"
            class="social-icon d-flex align-items-center justify-content-center">
            <span class="fab fa-instagram"></span>
            </a>
          <a href="{{ linkedin_link }}" target="_blank" title="LinkedIn {{storeName}}"
            class="social-icon d-flex align-items-center justify-content-center">
            <span class="fab fa-linkedin"></span>
          </a>
          <a href="{{ twiiter_link }}" target="_blank" title="Twitter {{storeName}}"
            class="social-icon d-flex align-items-center justify-content-center">
            <span class="fab fa-twitter"></span>
          </a>
        </p>
        <br>
        <div class="alert alert-danger content-message" *ngIf="error">
          <i class="fa-solid fa-circle-info"></i> {{msg}}
        </div>
      </div>
      <div class="form__group">
        <i class="fa-solid fa-envelope form__icon"></i>
        <input type="text" id="user-mail" placeholder="E- mail" required class="form__input validate form-control" autocomplete="username"/>
        <span class="form__bar"></span>
      </div>
      <div class="form__group">
        <i class="fa-solid fa-key form__icon"></i>
        <input type="password" placeholder="Senha" required id="user-password" class="form__input validate form-control" autocomplete="current-password"
          (keyup.enter)="signInWithEmail()" />
        <span class="form__bar"></span>
      </div>
      <a (click)="redirectToForgotPassword()" class="forgot-password">Esqueceu a senha?</a>
      <button type="submit" id="sign-button" class="form__button" (click)="signInWithEmail()">
        <i class="fas fa-sign-in"></i> Acessar
      </button>
      <p class="form__switch">
        Não tem uma conta? <a [routerLink]="['/register']">Cadastre-se aqui</a>
      </p>
    </form>
  </div>
</section>


import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { UserService } from '../../services/user/user.service';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  user;
  clients;
  storeId;

  loadedData: boolean = false;

  avatars = [];
  branches = [];

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private toastrService: ToastrService,
  ) {

  }

  ngOnInit() {
    this.setAvatars();
    this.getUser();
  }

  openSelectAvatarModal() {
    $('#avatar-modal').modal('show');

    if (typeof this.user.avatar !== 'undefined') {
      $(`#avatar_${this.user.avatar.name}`).prop('checked', true);
    }
  }

  setAvatars() {
    for (let i = 0; i <= 10; i++) {
      this.avatars.push({
        name: 'avatar',
        version: i,
      });
    }
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];

        this.storeId = res['user']['store']['id'];

        this.getClients(res['user']['company_id']);

        if (this.user.branch_id === null) {
          this.user.branch_id = '';
        }

        if (this.user['avatar'] && this.user['avatar'] !== '{}') {
          this.user['avatar'] = JSON.parse(this.user['avatar']);
        } else {
          this.user['avatar'] = {
            name: 'avatar',
            version: 1
          };
        }
      }

      this.getBranches();
    });
  }

  getClients(companyId) {
    this.apiService.getStoresByCompany('?company_id=' + companyId).subscribe((res) => {
      if (res['error']) {
        console.error(res['message']);
      } else {
        this.clients = res['resp']['stores'];
        if (this.clients !== undefined) {
          this.clients.forEach(cli => {
            cli['client_since'] = cli['client_since'] ? moment(cli['client_since'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : '-';
          });
        }
      }
    });
  }

  changeAvatar(avatar) {
    this.user.avatar = avatar;
  }

  saveAvatar() {
    const avatar = {
      'name': this.user.avatar.name,
      'version': this.user.avatar.version,
    };

    this.apiService.updateUserData(this.user.id, {
      avatar: JSON.stringify(avatar),
    }).subscribe((res) => {
      if (!res['error']) {
        this.toastrService.showSuccess(res['message']);
      } else {
        this.toastrService.showError(res['message']);
      }
    });
  }

  save() {
    const btn = $('#save-user');
    btn.html("<i class='fa-solid fa-spinner fa-spin'></i>");

    this.apiService.updateUserData(this.user.id, {
      branch_id: this.user.branch_id,
      name: this.user.name,
      mobile_number: this.user.phone_number,
      store_id: this.storeId
    }).subscribe((res) => {
      if (!res['error']) {
        this.toastrService.showSuccess(res['message']);
      } else {
        this.toastrService.showError(res['message']);
      }

      btn.html("<i class='fas fa-save'></i> Alterar");
    });
  }

  getBranches() {
    this.apiService.getBranches().subscribe((res) => {
      if (!res['error']) {
        const branches = res['enterprises'];

        if (this.user.store.id) {
          branches.filter((branch) => {
            if (branch['stores_enterprise']) {
              const hasStore = branch.stores_enterprise.find(item => String(item.store_id) === String(this.user.store.id));

              if (hasStore) {
                this.branches.push(branch);
              }
            }
          });
        }
      }

      this.loadedData = true;
    });
  }

}

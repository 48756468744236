<div *ngIf="!showSpinner" class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>

<div class="sellentt-container" *ngIf="showSpinner">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h1 class="text-center text-uppercase pb-5">
        <b>Listas de Desejos</b>
      </h1>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="thead thead-color">
          <tr>
            <th scope="col" class="text-center"></th>
            <th scope="col" class="text-center">Nome da Lista</th>
            <th scope="col" class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wishlist of wishlists">
            <td class="text-center">
              <i class="fa-solid fa-list icon-color"></i>
            </td>
            <td class="text-center">{{ wishlist.name }}</td>
            <td class="text-center">
              <button type="button" class="btn btn-success mr-2" [routerLink]="['/favorites-details', wishlist.id]" title="Ver detalhes">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-primary mr-2" (click)="openEditModal(wishlist.id)" title="Editar">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-danger" (click)="openDelModal(wishlist.id)" title="Excluir">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--modal para editar nome da lista-->
<div class="modal inmodal" id="modal-edit-wishlist" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Novo nome para a lista</h4>
      </div>
      <div class="modal-body">
        <div class="form-group" *ngIf="selectedWishlistId !== null">
          <div class="d-flex align-items-center">
            <label class="col-md-2">Nome:</label>
            <input type="text" class="form-control col-md-6" name="newName" id="newName"
              [(ngModel)]="newName" placeholder="Digite o novo nome da lista">
            <button type="button" class="btn btn-primary float-right col-md-4 ml-1" data-dismiss="modal"
              aria-label="Close" (click)="editWishlist(selectedWishlistId)">Confirmar</button>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>

<!--modal aviso de delete da lista-->
<div class="modal inmodal" id="modal-delete-wishlist" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">
      <div class="form-group modal-delete-wishlist-form-group">
        <div class="d-flex align-items-center">
          <h3>Tem certeza que deseja excluir esta lista de desejos?</h3>
        </div>
      </div>
      <div *ngIf="deletedWishlistId !== null" class="modal-delete-wishlist-buttons">
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="deleteWishlist()"> Confirmar
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal"
          aria-label="Close"> Cancelar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-regular fa-address-book secondary"></i> Meus Dados</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Informações de sua conta e seus respectivos dados.</p>
      </h2>
    </div>
    <div class="sellentt-col-xl-12">
      <div class="product-wrapper card h-100">
        <div class="sellentt-col-xl-12">
          <div class="sellentt-row p-5">
            <button type="button" (click)="openSelectAvatarModal();" class="not-button">
              <img src="https://p.biaction.com.br/108/users/avatares/{{user.avatar.name + user.avatar.version}}.png"
                class="avatar avatar-sellentt">
            </button>
            <small class="text-center p-3">Usuário desde <b>{{ user.created_at | date : 'dd/MM/yyyy' }}</b></small>

            <div class="sellentt-col-3 p-3">
              <label for="full-name">
                <i class="fa-solid fa-file-signature secondary"></i> Nome Completo *
              </label>
              <input type="text" class="form-control" id="full-name" [(ngModel)]="user.name">
            </div>
            <div class="sellentt-col-3 p-3">
              <label for="phone-number">
                <i class="fa-solid fa-square-phone secondary"></i> Celular *
              </label>
              <input type="text" class="form-control" id="phone-number" [(ngModel)]="user.mobile_number">
            </div>
            <div class="sellentt-col-3 p-3">
              <label for="email">
                <i class="fa-solid fa-at secondary"></i> E-mail
              </label>
              <input type="email" class="form-control" id="email" [(ngModel)]="user.email" readonly>
            </div>
            <div class="sellentt-col-3 p-3">
              <label for="company">
                <i class="fa-solid fa-id-card-clip secondary"></i> Grupo de Cliente
              </label>
              <input type="text" class="form-control" id="company" [(ngModel)]="user.company.name" readonly>
            </div>
            <div class="sellentt-col-3 p-3">
              <label for="store">
                <i class="fa-solid fa-address-card secondary"></i> Cliente
              </label>
              <select name="clients" id="clientsl" class="form-control form-select" [(ngModel)]="storeId">
                <option *ngFor="let client of clients" value="{{ client.id }}">{{ client.name }}</option>
              </select>
            </div>
            <div class="sellentt-col-3 p-3" *ngIf="branches.length > 1">
              <label for="branch_id">
                <i class="fa-solid fa-building secondary"></i> Filial
              </label>
              <select class="form-control" id="branch_id" [(ngModel)]="user.branch_id">
                <option value="">- Selecionar -</option>
                <option value="{{branch.id}}" *ngFor="let branch of branches">
                  {{branch.name}}
                </option>
              </select>
            </div>
            <div class="sellentt-col-3 p-3">
              <label for="password">
                <i class="fa-solid fa-key secondary"></i> Senha
              </label>
              <input type="password" class="form-control" id="password">
            </div>
          </div>

          <div class="sellentt-row px-5">
            <div class="sellentt-col-6 text-right pb-5">
              <button type="button" class="btn btn-warning" id="save-user" (click)="save()">
                <i class="fas fa-save"></i> Alterar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="avatar-modal" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="loadedData">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="text-center">
          <i class="fa-solid fa-user-plus secondary mr-2"></i>
          <b>Selecione seu avatar</b>
        </h2>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button> -->
      </div>
      <div class="modal-body">
        <div class="sellentt-row">
          <div class="sellentt-col-12 sellentt-col-sm-6 sellentt-col-lg-3 mb-4"
            *ngFor="let avatar of avatars; let i = index;">
            <input type="radio" name="avatar" value="{{ i }}" id="avatar_{{ i }}" class="radio-image-input"
              (change)="changeAvatar(avatar)">
            <label for="avatar_{{ i }}">
              <img class="w-100 radio-image-label avatar-preview avatar-sellentt"
                [src]="'https://p.biaction.com.br/108/users/avatares/' + avatar.name + avatar.version + '.png'"
                [attr.data-target]="'#carouselExample' + i" attr.data-slide-to="{{ i }}">
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="saveAvatar()">
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          <i class="fa-regular fa-circle-xmark"></i> Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="sellentt-container" *ngIf="order">
  <div class="sellentt-row m-t-lg">
    <div class="sellentt-col-xl-12">
      <div class="sellentt-row">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card h-100 p-5">
            <div class="sellentt-row">
              <div class="sellentt-col-x1-6">
                <div class="sellentt-row">
                  <div class="sellett-col-md-6" align="left">
                    <h1 class="text-bold secondary">
                      <i class="fa-solid fa-file-invoice"></i> Dados do Pedido
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="code">
                    <i class="fa-solid fa-user-check"></i> Código
                  </label>
                  <input type="text" class="form-control" name="code" id="code" [(ngModel)]="order.code" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="erp_code">
                    <i class="fa-solid fa-user-check"></i> Código ERP
                  </label>
                  <input type="text" class="form-control" name="erp_code" id="erp_code" [(ngModel)]="order.erp_id" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="status_name">
                    <i class="fa-solid fa-clone"></i> Status
                  </label>
                  <input type="text" class="form-control" name="status_name" id="status_name" [(ngModel)]="order.status && order.status.name" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="created_at">
                    <i class="fa-solid fa-calendar-days"></i> Data
                  </label>
                  <input type="text" class="form-control" name="created_at" id="created_at" [value]="order.created_at | date: 'dd/MM/yyyy H:mm'" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellentt-col-md-12">
                <div class="form-group">
                  <label for="shipping_cost">
                    <i class="fa-solid fa-comment"></i> Observação
                  </label>
                  <textarea class="form-control" name="shipping_cost" id="shipping_cost"
                            rows="8" [(ngModel)]="order.obs" disabled></textarea>
                </div>
              </div>
            </div>
            <div class="sellentt-row py-4">
              <div class="sellett-col-md-12">
                <h1 class="text-bold secondary">
                  <i class="fa-solid fa-address-card"></i> Endereço de Cobrança
                </h1>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <div class="sellentt-col-md-6">
                <div class="form-group">
                  <label for="address_street">
                    <i class="fa-solid fa-road"></i> Logradouro
                  </label>
                  <input type="text" class="form-control" name="address_street" id="address_street"
                         [(ngModel)]="order.store.address_street" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-2">
                <div class="form-group">
                  <label for="address_number">
                    <i class="fa-solid fa-list-ol"></i> Número
                  </label>
                  <input type="text" class="form-control" name="address_number" id="address_number"
                         [(ngModel)]="order.store.address_number" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-4">
                <div class="form-group">
                  <label for="address_more">
                    <i class="fa-solid fa-person-circle-plus"></i> Complemento
                  </label>
                  <input type="text" class="form-control" name="address_more" id="address_more"
                         [(ngModel)]="order.store.address_more" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_zipcode">
                    <i class="fa-solid fa-location-dot"></i> CEP
                  </label>
                  <input type="text" class="form-control" name="address_zipcode" id="address_zipcode"
                         [(ngModel)]="order.store.address_zipcode" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_district">
                    <i class="fa-solid fa-earth-europe"></i> Bairro
                  </label>
                  <input type="text" class="form-control" name="address_district" id="address_district"
                         [(ngModel)]="order.store.address_district" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_city">
                    <i class="fa-solid fa-map-location"></i> Cidade
                  </label>
                  <input type="text" class="form-control" name="address_city" id="address_city"
                         [(ngModel)]="order.store.address_city" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_state">
                    <i class="fa-solid fa-flag-usa"></i> Estado
                  </label>
                  <input type="text" class="form-control" name="address_state" id="address_state"
                         [(ngModel)]="order.store.address_state" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row py-4">
              <div class="sellett-col-md-12">
                <h1 class="text-bold secondary">
                  <i class="fa-solid fa-address-card"></i> Endereço de Entrega
                </h1>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <div class="sellentt-col-md-6">
                <div class="form-group">
                  <label for="address_street_delivery">
                    <i class="fa-solid fa-road"></i> Logradouro
                  </label>
                  <input type="text" class="form-control" name="address_street_delivery" id="address_street_delivery"
                         [(ngModel)]="order.store.address_street_delivery" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-2">
                <div class="form-group">
                  <label for="address_number_delivery">
                    <i class="fa-solid fa-list-ol"></i> Número
                  </label>
                  <input type="text" class="form-control" name="address_number_delivery" id="address_number_delivery"
                         [(ngModel)]="order.store.address_number_delivery" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-4">
                <div class="form-group">
                  <label for="address_more_delivery">
                    <i class="fa-solid fa-person-circle-plus"></i> Complemento
                  </label>
                  <input type="text" class="form-control" name="address_more_delivery" id="address_more_delivery"
                         [(ngModel)]="order.store.address_more_delivery" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_zipcode_delivery">
                    <i class="fa-solid fa-location-dot"></i> CEP
                  </label>
                  <input type="text" class="form-control" name="address_zipcode_delivery" id="address_zipcode_delivery"
                         [(ngModel)]="order.store.address_zipcode_delivery" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_district_delivery">
                    <i class="fa-solid fa-earth-europe"></i> Bairro
                  </label>
                  <input type="text" class="form-control" name="address_district_delivery" id="address_district_delivery"
                         [(ngModel)]="order.store.address_district_delivery" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_city_delivery">
                    <i class="fa-solid fa-map-location"></i> Cidade
                  </label>
                  <input type="text" class="form-control" name="address_city_delivery" id="address_city_delivery"
                         [(ngModel)]="order.store.address_city_delivery" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="address_state_delivery">
                    <i class="fa-solid fa-flag-usa"></i> Estado
                  </label>
                  <input type="text" class="form-control" name="address_state_delivery" id="address_state_delivery"
                         [(ngModel)]="order.store.address_state_delivery" disabled>
                </div>
              </div>
            </div>

            <div class="sellentt-row" *ngIf="confirmation.reason">
              <div class="col-md-12">
                <hr>
                <div class="form-group">
                  <label><i class="fa-solid fa-comment"></i> Justificativa para atestamento parcial</label>
                  <textarea class="form-control" name="abservations" cols="30" rows="10" [value]="confirmation.reason" disabled></textarea>
                </div>
              </div>
            </div>

            <div class="container attest-content mt-lg-5">
              <div class="row">
                <div class="col-md-4"><button (click)="sendJustify(1)" [disabled]="certified" class="btn btn-default attest-total" (mouseover)="toggleClass('attest-total-icon','fa-bounce', true)" (mouseout)="toggleClass('attest-total-icon','fa-bounce')"><i id="attest-total-icon" class="fa-solid fa-thumbs-up" style="color:white"></i> Atestar Total</button></div>
                <div class="col-md-4"><button [disabled]="certified" class="btn btn-default attest-partial" data-toggle="modal" data-target="#send-justify-modal"><i class="fa fa-hourglass-half" style="margin-right: 5px;"></i>Atestar Parcial</button></div>
                <div class="col-md-4"><button (click)="sendJustify(3)" [disabled]="certified" class="btn btn-default contest-total" (mouseover)="toggleClass('contest-total-icon','fa-shake', true)" (mouseout)="toggleClass('contest-total-icon','fa-shake')"><i id="contest-total-icon" class="fa-solid fa-thumbs-down" style="color:white"></i> Constestar Total</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="send-justify-modal" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated fadeIn">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
          class="sr-only">Close</span></button>
        <h4 class="modal-title">Justificativa para Atestar Parcialmente</h4>
      </div>
      <form #justifyForm="ngForm" (ngSubmit)="sendJustify(2)">
        <div class="modal-body">
          <div class="form-group" [class.has-error]="!justifyField.valid && justifyField.touched">
            <label><i class="fa-solid fa-comment"></i> Justificativa</label>
            <textarea class="form-control" name="justify" cols="30" rows="10" #justifyField="ngModel" [(ngModel)]="confirmation.reason"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal"><i class="fas fa-times"></i> Fechar</button>
          <button type="submit" class="btn btn-warning" [disabled]="!justifyForm.valid"><i class="fa-regular fa-paper-plane"></i> Enviar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center align-items-center vh-100 text-center bg-light">
  <div>
    <h1 class="mt-3 fw-bold text-primary">Ecommerce em Manutenção</h1>
    <img src="assets/svg/maintenance.svg" alt="Manutenção" class="img-fluid mb-4"
      style="width: 70%; max-width: 300px;" />
    <p class="mt-2 fs-5 text-muted">
      Estamos trabalhando para trazer novidades incríveis para você. Volte em breve!
    </p>
    <div class="mt-2">


      <!-- Bloco de contatos formatados -->
      <div class="d-flex flex-column align-items-center mt-3 text-muted">

        <div class="mt-2">
          <h4 class="fw-bold text-secondary">Entre em Contato Conosco</h4>
          <div class="mt-3">
            <div class="d-flex align-items-center text-muted">
              <i class="fas fa-envelope me-2"></i>
              <span style="margin-left: 10px;">{{ecommerceConfigs['email']}}</span>
              <span class="mx-3">|</span>
              <i class="fas fa-phone me-2"></i>
              <span style="margin-left: 10px;">{{ecommerceConfigs['telefone']}}</span>
            </div>
          </div>
        </div>

        <!-- Redes sociais -->
        <div class="d-flex justify-content-center gap-3 mt-4">
          <!-- Facebook -->
          <a href="{{ ecommerceConfigs['facebook'] }}" class="text-decoration-none text-dark" target="_blank"
            *ngIf="ecommerceConfigs['facebook']">
            <i class="fab fa-facebook-f" style="color: #3b5998; font-size: 20px;"></i>
          </a>

          <!-- Instagram -->
          <a href="{{ ecommerceConfigs['instagram'] }}" class="text-decoration-none text-dark" target="_blank"
            *ngIf="ecommerceConfigs['instagram']">
            <i class="fab fa-instagram" style="color: #fb3958; margin-left: 10px; font-size: 20px;"></i>
          </a>

          <!-- Linkedin -->
          <a href="{{ ecommerceConfigs['linkedin'] }}" class="text-decoration-none text-dark" target="_blank"
            *ngIf="ecommerceConfigs['linkedin']">
            <i class="fab fa-linkedin" style="color: #0077b5; margin-left: 10px; font-size: 20px;"></i>
          </a>

          <!-- Twitter -->
          <a href="{{ ecommerceConfigs['twitter'] }}" class="text-decoration-none text-dark" target="_blank"
            *ngIf="ecommerceConfigs['twitter']">
            <i class="fab fa-twitter" style="color: #00acee; margin-left: 10px; font-size: 20px;"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ======================= category products ============================ -->
<div class="home-section mt-5 mb-5" *ngIf="loadedData">
  <div class="sellentt-container">
    <div class="sellentt-row">
      <div class="sellentt-col-xl-2">
        <div class="shop-sidebar">

          <!-- close filter button -->
          <div class="close-sidebar-filter d-block d-xl-none">
            <span class="icon">
              <i class="las la-times"></i>
            </span>
          </div>

          <div class="shop-filter">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <!-- Categorias -->
              <div class="accordion-item">
                <h3 class="accordion-header" id="heading_4">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_4"
                    aria-expanded="true" aria-controls="collapse_5">
                    Categorias
                  </button>
                </h3>
                <div id="collapse_4" class="accordion-collapse collapse show" aria-labelledby="heading_4"
                  data-bs-parent="#accordionExample">
                  <div class="row">
                    <div class="col-lg-12 m-2">
                      <button type="button" class="btn btn-primary btn-lg w-100 text-left font-weight-bold"
                        (click)="navigateToDifferentCategory(category)">
                        &nbsp;{{category.name}}
                      </button>
                    </div>
                  </div>
                  <div class="row" *ngIf="category.children.length > 0">
                    <div class="col-lg-12 m-2" *ngFor="let child of category.children | slice: 0:countChildren*40">
                      <button type="button" class="btn btn-primary btn-lg w-100 text-left"
                        (click)="navigateToDifferentCategory(child)">
                        &nbsp;- {{ child.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sellentt-col-xl-10">
        <div class="shop-products">
          <!-- filters start -->
          <div class="shop-header mb-4">
            <div class="row mt-3 filters">
              <div class="col-md-10">
                <app-products-filter [orderTypeId]="selectedOrderType['id']" [categoryId]="categoryId"
                                     [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null"
                                     [page]="page" [screen]="'categories'" (filter_data)="filterProducts($event)"></app-products-filter>
              </div>
              <div class="col-md-2 p-0 shop-view-btn" style="margin-top:18px">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <!-- tabela -->
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-list-tab" (click)="changeColor($event);" data-bs-toggle="pill"
                       data-bs-target="#pills-list" type="button" role="tab" aria-controls="pills-list"
                       aria-selected="false" style="background-color: transparent!important" href="#pills-list"
                       target="_self">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg" style="margin-top: 5px;">
                        <path d="M8 6H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M8 12H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M8 18H21" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 6H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 12H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                        <path d="M3 18H3.01" stroke="#3E424C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-inactive"></path>
                      </svg>
                    </a>
                  </li>
                  <!-- listagem -->
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="pills-grid-tab" (click)="changeColor($event);"
                       data-bs-toggle="pill" data-bs-target="#pills-grid" type="button" role="tab"
                       aria-controls="pills-grid" aria-selected="true"
                       style="background-color: transparent!important" href="#pills-grid" target="_self">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 7H7V14H14V7Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-active"></path>
                        <path d="M25 7H18V14H25V7Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-active"></path>
                        <path d="M25 18H18V25H25V18Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-active"></path>
                        <path d="M14 18H7V25H14V18Z" stroke="#ed8823" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon-active"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- filters end -->

          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab"
              tabindex="0">

              <div
                class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
                *ngIf="paginatedData.length > 0">
                <div class="sellentt-col" *ngFor="let item of paginatedData; trackBy: trackByFn">
                  <app-product [type]="'card'" [product]="item"></app-product>
                </div>
              </div>

              <div class="sellentt-row sellentt-row-cols-12" *ngIf="paginatedData.length === 0 && loadedData">
                <div class="sellentt-col">
                  <div class="alert alert-warning text-center">
                    <h2>
                      <i class="fa-solid fa-exclamation-circle"></i>
                      Nenhum produto encontrado!
                    </h2>
                  </div>
                </div>
              </div>

              <!-- pagination -->
              <div class="pagination mt-5 justify-content-center" *ngIf="paginatedData.length > 0">
                <nav>
                  <sellentt-pagination (pageChanged)="onPageChange($event)" [page]="page" [hitsPerPage]="hitsPerPage"
                    [nbHits]="nbHits" [nbPages]="nbPages" [maxSize]="maxSize">
                  </sellentt-pagination>
                </nav>
              </div>

            </div>

            <div class="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab" tabindex="0">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <ng-container *ngFor="let item of paginatedData">
                      <app-product [type]="'list'" [product]="item"></app-product>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div
                class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
                *ngIf="paginatedData.length === 0">
                <div class="sellentt-col">
                  Desculpe, não encontramos nenhum produto.
                </div>
              </div>

              <!-- pagination -->
              <div class="pagination mt-5 justify-content-center">
                <nav>
                  <sellentt-pagination (pageChanged)="onPageChange($event)" [page]="page" [hitsPerPage]="hitsPerPage"
                    [nbHits]="nbHits" [nbPages]="nbPages" [maxSize]="maxSize">
                  </sellentt-pagination>
                </nav>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- ======================= category products ============================ -->
<div class="modal inmodal" id="modal-products-infos" tabindex="-1" role="dialog" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated fadeIn">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
        <h4 class="modal-title"><i class="fa-solid fa-file-lines iconSellentt"></i> Informações Adicionais </h4>
      </div>
      <div class="modal-body">
        <span>
          <h4>PAUTA: {{productSelected.pauta || 0}}%</h4>
          <h4>ST: {{productSelected.st || 0}}%</h4>
          <h4>IPI: {{productSelected.ipi || 0}}%</h4>
        </span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal"><i class="fas fa-times"></i>
          Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="col-lg-12 mt-3">
  <table class="float-right">
    <tr>
      <td>
        <nav aria-label="...">
          <ul class="pagination">
            <li *ngIf="current_page !== 1">
              <a (click)="setPage(1, $event)" class="pagination-button"><i class="fa-solid fa-angle-double-left"></i></a>
            </li>
            <li *ngIf="current_page !== 1"
              [ngClass]="[current_page === final_page ? 'disabled' : '']">
              <a (click)="setPage(current_page - 1, $event)" class="pagination-button"><i class="fa-solid fa-angle-left"></i></a>
            </li>
            <li class="page-item" *ngFor="let page of page_array" [ngClass]="[page == current_page ? 'active' : '']">
              <a (click)="setPage(page, $event)" class="page-link" >
                {{ page }}
              </a>
            </li>
            <li class="page-item" *ngIf="final_page !== current_page">
              <span class="page-link">
                ...
              </span>
            </li>
            <li class="page-item" *ngIf="final_page !== current_page">
              <a (click)="setPage(final_page, $event)" class="page-link">
                {{ final_page }}
              </a>
            </li>
            <li *ngIf="final_page > 1 && final_page !== current_page">
              <a (click)="setPage(current_page + 1, $event)" class="pagination-button"><i class="fa-solid fa-angle-right"></i></a>
            </li>
            <li *ngIf="final_page > 1 && final_page !== current_page"
              [ngClass]="[current_page === final_page ? 'disabled' : '']">
              <a (click)="setPage(final_page, $event)" class="pagination-button"><i class="fa-solid fa-angle-double-right"></i></a>
            </li>
          </ul>
        </nav>
      </td>
    </tr>
  </table>
</div>
<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-xl-12">
      <div class="sellentt-row" style="margin-top: 30px;">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card h-100 p-5">
            <div class="sellentt-row">
              <div class="sellentt-col-x1-6">
                <div class="sellentt-row">
                  <div class="sellett-col-md-6" align="left">
                    <h1 class="text-bold secondary">
                      <i class="fa-solid fa-file-invoice"></i> Dados do Pedido
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="code">
                    <i class="fa-solid fa-user-check"></i> Código
                  </label>
                  <input type="text" class="form-control" name="code" id="code" [(ngModel)]="order.code" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="erp_id">
                    <i class="fa-solid fa-user-check"></i> Código ERP
                  </label>
                  <input type="text" class="form-control" name="erp_id" id="erp_id" [(ngModel)]="order.erp_id" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="status_name">
                    <i class="fa-solid fa-clone"></i> Status
                  </label>
                  <input type="text" class="form-control" name="status_name" id="status_name"
                    [(ngModel)]="order.status.name" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="created_at">
                    <i class="fa-solid fa-calendar-days"></i> Data
                  </label>
                  <input type="text" class="form-control" name="created_at" id="created_at"
                    [(ngModel)]="order.created_at" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellett-col-md-12">
                <h1 class="text-bold secondary">
                  <i class="fa-solid fa-store"></i> Dados do Cliente
                </h1>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="doc_number">
                    <i class="fa-solid fa-building"></i> Tipo de Cliente
                  </label>
                  <select class="form-control" [(ngModel)]="order.store.doc_type" disabled>
                    <option value="cpf">Pessoa Física</option>
                    <option value="cnpj">Pessoa Jurídica</option>
                  </select>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="status_name">
                    <i class="fa-solid fa-user-pen"></i> Nome Completo
                  </label>
                  <input type="text" class="form-control" name="status_name" id="status_name" [(ngModel)]="order.store.name" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="shipping_cost">
                    <i class="fa-solid fa-id-card-clip"></i> CPF
                  </label>
                  <input type="text" class="form-control" name="shipping_cost" id="shipping_cost" [(ngModel)]="order.store.doc_number" disabled>
                </div>
              </div>
              <div class="sellentt-col-md-3">
                <div class="form-group">
                  <label for="shipping_cost">
                    <i class="fa-solid fa-id-card"></i> RG
                  </label>
                  <input type="text" class="form-control" name="shipping_cost" id="shipping_cost" [(ngModel)]="order.store.reg_number" disabled>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellentt-col-md-12">
                <h1 class="text-bold secondary">
                  <i class="fa-solid fa-basket-shopping"></i> Avaliação dos Produtos
                </h1>
              </div>
            </div>
            <hr>
            <div class="sellentt-row">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2" width="60">Produto</th>
                    <th>Estrelas</th>
                    <th>Avaliação</th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  <tr *ngFor="let item of order.products">
                    <td>
                      <img src="{{ item.image }}" class="product-image">
                    </td>
                    <td>
                      <div class="item-container">
                        <div>{{ item.name }} ({{ item.code }})</div>
                      </div>
                    </td>
                    <td>
                      <div class="stars">
                        <input id="one_{{item.id}}" type="checkbox" name="stars_{{item.id}}" value="one" (click)="rating(1, item.id)" checked="checked" />
                        <label class="star one" for="one_{{item.id}}">
                          <i class="fa-solid fa-star icon"></i>
                        </label>

                        <input id="two_{{item.id}}" type="checkbox" name="stars_{{item.id}}" value="two" (click)="rating(2, item.id)" checked="checked" />
                        <label class="star two" for="two_{{item.id}}">
                          <i class="fa-solid fa-star icon"></i>
                        </label>

                        <input id="three_{{item.id}}" type="checkbox" name="stars_{{item.id}}" value="three" (click)="rating(3, item.id)" checked="checked" />
                        <label class="star three" for="three_{{item.id}}">
                          <i class="fa-solid fa-star icon"></i>
                        </label>

                        <input id="four_{{item.id}}" type="checkbox" name="stars_{{item.id}}" value="four" (click)="rating(4, item.id)" checked="checked" />
                        <label class="star four" for="four_{{item.id}}">
                          <i class="fa-solid fa-star icon"></i>
                        </label>

                        <input id="five_{{item.id}}" type="checkbox" name="stars_{{item.id}}" value="five" (click)="rating(5, item.id)" checked="checked" />
                        <label class="star five" for="five_{{item.id}}">
                          <i class="fa-solid fa-star icon"></i>
                        </label>
                      </div>
                    </td>
                    <td>
                      <select  class="form-control spacing-select" id="product_{{item.id}}_options" *ngIf="showSelect">
                        <option>- Selecione -</option>
                        <option *ngFor="let option of order_review_options" [value]="option">{{ option }}</option>
                      </select>
                      <textarea class="form-control spacing" id="product_{{item.id}}_review"  cols="60" rows="15"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="sellentt-row">
              <div class="sellentt-col-md-12">
                <div class="form-group">
                  <label for="order-review">
                    <i class="fa-solid fa-comment"></i> Avaliação Geral do Pedido
                  </label>
                  <textarea class="form-control" name="order-review" id="order-review" [(ngModel)]="review.review"
                    cols="60" rows="10">
                                    </textarea>
                </div>
              </div>
            </div>
            <div class="sellentt-row">
              <div class="sellentt-col-md-12 pt-3" align="right">
                <button type="button" class="btn btn-warning btn-lg" (click)="saveReview()" id="save">
                  <i class="fa-solid fa-star" style="color: white;"></i> Enviar Avaliação
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

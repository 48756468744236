<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-solid fa-clipboard-list secondary"></i> Listas Sugeridas</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Informações de suas listas sugeridas para visualização.</p>
      </h2>
    </div>
    <div class="panel-group sellentt-accordion" id="accordion" *ngIf="suggested_lists.length > 0">
      <div class="panel panel-default" *ngFor="let list of suggested_lists">
        <div class="panel-heading">
          <h4 class="panel-title">
            <div class="sellentt-row info-container">
              <div class="sellentt-col-md-3 info-col sellentt-col-sm-8 sellentt-col-xs-8">
                <div class="info-title">Nome</div>
                <div class="info-content">{{ list.name || '-' }}</div>
              </div>
              <div class="sellentt-col-md-3 hidden-xs info-col">
                <div class="info-title">Criado em</div>
                <div class="info-content">{{ list.created_at | date : 'dd/MM/yyyy' }}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Número de Produtos</div>
                <div class="info-content">{{ list.products.length }}</div>
              </div>
              <div class="sellentt-col-md-2 sellentt-col-sm-2 sellentt-col-xs-2 info-col-action">
                <div class="info-content">
                  <a [routerLink]="'#'">
                    <i class="fa-solid fa-cart-plus"></i> <span class="hidden-xs"> Carrinho</span>
                  </a>
                </div>
              </div>
              <div class="sellentt-col-md-2 sellentt-col-sm-2 sellentt-col-xs-2 info-col-action">
                <div class="info-content">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse-{{list.id}}">
                    <i class="fa-solid fa-pen-to-square"></i> <span class="hidden-xs"> Detalhes da lista</span>
                  </a>
                </div>
              </div>
            </div>
          </h4>
        </div>


        <div id="collapse-{{list.id}}" class="panel-collapse collapse in">
          <hr class="initial-divisor">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <h2 class="text-bold">Produtos</h2>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Preço un.</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of list.products" class="active-row">
                        <td>{{ item.name || '-' }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.unit_price | currency : 'BRL' }}</td>
                        <td>{{ item.unit_price | currency : 'BRL' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="password-box">
  <div class="password-container">
    <form #formEmail="ngForm" (ngSubmit)="sendEmail()">
      <div class="row">
        <div class="col-md-12" [class.input-error]="!emailInput.valid && emailInput.touched">
          <div class="alert alert-warning content-message"><i class="fa-solid fa-circle-info"></i> Informe o e-mail cadastrado para enviarmos as instruções para redefinição de senha. </div>
          <div class="form-group">
            <label *ngIf="!emailInput.valid && emailInput.touched">E-mail é obrigatório</label>
            <input type="email" class="form-control" name="email" placeholder="E-mail de cadastro" required #emailInput="ngModel" email="true" [(ngModel)]="email">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="submit" [disabled]="!formEmail.valid"><i class="fa-solid fa-paper-plane"></i> Enviar e-mail</button>
        </div>
      </div>
    </form>
    <a [routerLink]="'/login'" class="back-to-login"><i class="fa-solid fa-arrow-left-long"></i> Voltar para login</a>
  </div>
</div>

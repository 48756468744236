<div class="row">
  <div  class="col-md-6">
    <div class="form-group">
      <label for="find-prod"><i class="fa-solid fa-box iconSellentt"></i> Digite o <b>nome</b> o <b>código</b> ou o
        <b>EAN</b> do produto:</label>
      <input type="text" class="form-control" name="find-prod" id="find-prod">
    </div>
  </div>
  <div class="col-md-2" *ngIf="screen === 'home'">
    <div class="form-group">
      <label for="category"><i class="fa-solid fa-boxes-stacked iconSellentt"></i> Categoria:</label>
      <select class="form-control size-select" name="category" id="category">
        <option value="">Todas</option>
        <option *ngFor="let c of categories" value="{{c.id}}">{{c.name}}</option>
      </select>
    </div>
  </div>
  <div [ngClass]="screen === 'home' ? 'col-md-2' : 'col-md-4'">
    <div class="form-group">
      <label for="status_select"><i class="fa fa-clone iconSellentt"></i> Status:</label>
      <select class="form-control size-select" name="status_select" id="status_select" multiple>
        <option value="">Todas</option>
        <option *ngFor="let f of filters" value="{{f.value}}">{{f.label}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-2 filterBtn">
    <button class="btn btn-primary float-left" id="filterBtn" (click)="filter()">
      <i class="fa fa-search"></i> Consultar</button>
  </div>
</div>

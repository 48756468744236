import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

import * as numeral from 'numeral';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  loadedData: boolean = false;
  pageOfItems = [];

  user;
  orders = [];
  order: any;
  allOrders: any[] = [];


  api_conditions: { search: string | null, date: string | null } = { search: null, date: null };
  search = '';
  date = '';
  noResults: boolean = false;


  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getUserOrders();
    });
  }

  getUserOrders() {
    this.paginatedData = [];
    this.loadedData = false;

    this.apiService.getUserOrdersPaginated({
      user: this.user,
      page: this.page,
      hitsPerPage: this.hitsPerPage,
    }).subscribe((res) => {

      if (!res['error']) {
        this.orders = res['orders'];

        res['orders'].forEach((order) => {
          this.paginatedData.push(order);
        });

        this.noResults = false;
        this.nbHits = res['nbHits'];
        this.nbPages = res['nbPages'];
        this.maxSize = res['maxSize'];

      }

      this.loadedData = true;
    });
  }

  orderDetails(order) {
    this.order = order;

    this.router.navigate([`/orders/${this.order['code']}`]);
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getUserOrders();
  }

  filter() {
    this.api_conditions = {
      search: this.search ? this.search : null,
      date: this.date ? this.date : null
    };
    if (!this.api_conditions.search && !this.api_conditions.date) {
      this.getUserOrders();
    } else {
      this.page = 1;
      this.allOrders = [];

      this.getAllUserOrders();
    }
  }

  getAllUserOrders() {
    this.paginatedData = [];
    this.loadedData = false;
    this.noResults = false;

    const loadAllPages = (page) => {
      this.apiService.getUserOrdersPaginated({
        user: this.user,
        page: page,
        hitsPerPage: this.hitsPerPage,
      }).subscribe((res) => {
        if (!res['error']) {
          this.allOrders = this.allOrders.concat(res['orders']);
          if (page < res['nbPages'] && page < this.maxSize) {
            loadAllPages(page + 1);
          } else {
            this.applyFilters();
          }
        }
      });
    };
    loadAllPages(this.page);
  }


  applyFilters() {
    if (this.api_conditions.search || this.api_conditions.date) {
      this.paginatedData = this.allOrders.filter((order) => {
        const matchesSearch = this.api_conditions.search ?
          (order.code == this.api_conditions.search || order.buyer_name == this.api_conditions.search) : true;
        const matchesDate = this.api_conditions.date ?
          new Date(order.created_at).toISOString().split('T')[0] == this.api_conditions.date : true;

        return matchesSearch && matchesDate;
      });
      if (this.paginatedData.length === 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    } else {
      this.paginatedData = this.allOrders;
    }
    this.loadedData = true;
  }



}
